import {getUrlDate} from "@/utils/tool"

const envConfig = {
	dev: { // dev分支的环境配置
		meetServerUrl: 'https://meeting-dev-api.100url.cn',
    	middleServerUrl: 'https://mg.100api.cn',
		imEnv: 'dev',
	  	meetAgendasUrl: 'https://meeting-dev.100url.cn',
		signalUrl: 'live.100tt.com.cn/signal-server',
		reportLogUrl: 'https://meeting-dev-api.100url.cn/ybmeet-log/logan/web/upload.json',
		adminUrl: 'https://admin-meeting-dev.100url.cn/', 
    	workBenchServerUrl: "https://omnidevf.100url.cn",
		beautLicense:{
		  appId:`1252497236`,
		  licenseKey:`f4667fe5abe15501964d0e0c346b4980`,
		  token:'e16556dafe6b51099187cbd575ecffb5'
		},
    	consoleUrl: "https://switcher.100url.cn",
	},
	test: { // test分支的环境配置
		meetServerUrl: 'https://api.test.100meet.cn',
		middleServerUrl: 'https://api.test.100meet.cn',
		workBenchServerUrl: 'https://omnitest.100url.cn',
		imEnv: 'test',
		meetAgendasUrl: 'https://www.test.100meet.cn',
		signalUrl: 'signal.test.100rtc.com/signal-server',
		reportLogUrl: 'https://api.test.100meet.cn/ybmeet-log/logan/web/upload.json',
		adminUrl: 'https://admin.test.100meet.cn',
		beautLicense:{
		//   appId:`1253412830`,
		//   licenseKey:`2ce0fbc9ffbe351554f36dc9f4c7373e`,
		//   token:'71e707601a8b2c3ab79fed2f07cf2b06'
		  appId:`1252497236`,
		  licenseKey:`f4667fe5abe15501964d0e0c346b4980`,
		  token:'e16556dafe6b51099187cbd575ecffb5'
		},
    	consoleUrl: "https://switcher.test.100mix.cn",
	},
	master: { // master分支的环境配置
		meetServerUrl: 'https://api.100meet.cn',
		middleServerUrl: 'https://api.100meet.cn',
		workBenchServerUrl: 'https://npro.100url.cn',
		imEnv: 'prod',
		meetAgendasUrl: 'https://web.100meet.cn',
		signalUrl: 'signal.100rtc.com/signal-server',
		reportLogUrl: 'https://api.100meet.cn/ybmeet-log/logan/web/upload.json',
		adminUrl: 'https://admin.100meet.cn',
		beautLicense:{
		  appId:`1252497236`,
		  licenseKey:`d668e157d9e688f4216d8c3002e35eb1`,
		  token:'e16556dafe6b51099187cbd575ecffb5'
		},
    	consoleUrl: "https://console.100mix.cn",
	}
}


let paramsArr = getUrlDate(window.location.href)
export const meetServerUrl = envConfig[process.env.VUE_APP_ENV].meetServerUrl
export const middleServerUrl = envConfig[process.env.VUE_APP_ENV].middleServerUrl
export const workBenchServerUrl = paramsArr && paramsArr.domain ? 'https://' + decodeURIComponent(paramsArr.domain) : envConfig[process.env.VUE_APP_ENV].workBenchServerUrl

export const imEnv = envConfig[process.env.VUE_APP_ENV].imEnv
export const meetAgendasUrl = envConfig[process.env.VUE_APP_ENV].meetAgendasUrl
export const signalUrl = envConfig[process.env.VUE_APP_ENV].signalUrl
export const reportLogUrl = envConfig[process.env.VUE_APP_ENV].reportLogUrl
export const adminUrl = envConfig[process.env.VUE_APP_ENV].adminUrl
export const beautLicense = envConfig[process.env.VUE_APP_ENV].beautLicense
export const consoleUrl = envConfig[process.env.VUE_APP_ENV].consoleUrl


