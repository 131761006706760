/* 定义全局指令
 * 1.v-extend-panel-manager: 鼠标点击面板以外区域自动隐藏面板
 * 2.v-extend-keyup: 键盘按下延迟事件指令
 * 3.v-extend-mousewheel: 鼠标滚轮修正数据兼容指令
 * 4.v-extend-number-only: 只能输入整数功能
 * 5.v-extend-max-length: 最大长度截取指令
 * 6.v-extend-money-format: 金额格式化
 */
import Vue from 'vue'

// 事件处理（兼容）
function eventHandle(element, type, fn, eventMethodArray) {
    // W3C标准优先
    if (element.dispatchEvent) {
        element[eventMethodArray[0]](type, fn, false)
    } else {
        if (fn !== undefined) {
            element[eventMethodArray[1]]('on' + type, fn)
        }
    }
}

// 1.添加事件绑定（兼容）
function addEvent(element, type, fn) {
    eventHandle(element, type, fn, ['addEventListener', 'attachEvent'])
}

function removeEvent(element, type, fn) {
    eventHandle(element, type, fn, ['removeEventListener', 'detachEvent'])
}

// 3.通用绑定事件方法(event一个方法代替bind、unbind、live、die、delegated方法)
function event(element, type, fn) {
    removeEvent(element, type, fn)
    addEvent(element, type, fn)
    return this
}

const ctx = 'extendPanelManager'
const nodeList = []
const isServer = Vue.prototype.$isServer
let seed = 0
let startClick // 用来绑定事件的方法，它是一个自执行的函数，会根据是否运行于服务器和是否支持addEventListener来返回一个function
    // 触发之前会判断该元素是不是el，是不是focusElment以及他们的后代元素，如果是则不会执行函数
function createDocumentHandler(el, binding, vnode) {
    return function(mouseup = {}, mousedown = {}) {
        if (!vnode ||
            !vnode.context ||
            !mouseup.target ||
            !mousedown.target ||
            el.contains(mouseup.target) ||
            el.contains(mousedown.target) ||
            el === mouseup.target ||
            (vnode.context.focusElment && (vnode.context.focusElment.contains(mouseup.target) ||
                vnode.context.focusElment.contains(mousedown.target)))) {
            return
        }
        if (binding.expression && el[ctx].methodName &&
            vnode.context[el[ctx].methodName]) {
            vnode.context[el[ctx].methodName](mouseup, mousedown)
        } else {
            el[ctx].bindingFn && el[ctx].bindingFn()
        }
    }
}
if (!isServer) {
    addEvent(document, 'mousedown', e => (startClick = e))
    addEvent(document, 'mouseup', e => { // 循环所有的绑定节点，把它们的documentHandler属性所绑定的函数执行一次（这个时候得到的刚好是上面的那个判断执行的函数）
        nodeList.forEach(node => node && node[ctx] && node[ctx].documentHandler(e, startClick))
    })
}

// 1.v-extend-panel-manager: 鼠标点击面板以外区域自动隐藏面板
Vue.directive(ctx, {
    inserted(el) {}, // 只调用一次，指令第一次绑定到元素时调用。在这里可以进行一次性的初始化设置 // 把绑定的元素扔到nodeList里面，并给绑定元素设置属性 // documentHandler属性在nodeList.forEach的时候执行并得到一个function // bindingFn 是绑定的那个值，用来执行的
    bind(el, binding, vnode) {
        nodeList.push(el)
        const id = seed++
            el[ctx] = {
                id,
                documentHandler: createDocumentHandler(el, binding, vnode),
                methodName: binding.expression,
                bindingFn: binding.value
            }
    }, // 所在组件的VNode更新时调用
    update(el, binding, vnode) {
        if (el[ctx]) {
            el[ctx].documentHandler = createDocumentHandler(el, binding, vnode)
            el[ctx].methodName = binding.expression
            el[ctx].bindingFn = binding.value
        }
    }, // 只调用一次，指令与元素解绑时调用
    unbind(el, binding, vnode) {
        const len = nodeList.length
        for (let i = 0; i < len; i++) {
            if (nodeList && nodeList[i] && nodeList[i][ctx] && nodeList[i][ctx].id === el && el[ctx] && el[ctx].id) {
                nodeList.splice(i, 1)
                break
            }
        }
        delete el[ctx]
    }
})

// 4.通用绑定事件方法jq(event一个方法代替bind、unbind、live、die、delegated方法)
$.fn.event2 = function(o) {
    this.off(arguments[0]).on(arguments[0], arguments[1])
    return this
}

// 私有方法
function numberOnly(el) {
    let $el = $(el)
    $el.event2('keyup.numberOnly', function() {
        let that = this
        setTimeout(function() {
            that.value = that.value.replace(/\D+/, '')
        }, 0)
    }).event2('paste.numberOnly', function() {
        $el.trigger('keyup')
    })
}

function lazyFn(el, fn, time) {
    if (time === null) {
        time = 200
    }
    if (el.timer !== null) {
        clearTimeout(el.timer)
    }
    el.timer = setTimeout(function() {
        fn()
    }, time)
}

/*
 * 参数说明：
 * number：要格式化的数字
 * decimals：保留几位小数
 * decPoint
 * thousandsSep
 * */
function moneyFormat(number) {
    if (typeof number !== 'number') {
        return number
    }
    let re = /\d{1,3}(?=(\d{3})+$)/g
    let n1 = number
    if (number != null) {
        n1 = number.toString().replace(/^(\d+)((\.\d+)?)$/, function(s, s1, s2) {
            return s1.replace(re, '$&,') + s2
        })
    }
    return n1
}

let browser = (function() {
    let na = navigator.userAgent.toLocaleLowerCase() // 转成小写
    let browserArray = []
    let browser = {} // 保存浏览器信息数组，浏览器名称和版本，长度为2

    // 连续三元操作(模仿jquery)
    browserArray = na.match(/msie ([\d.]+)/)
    if (browserArray !== null && browserArray.length > 0) {
        browser.ie = browserArray[1]
    }
    browserArray = na.match(/firefox\/([\d.]+)/)
    if (browserArray !== null && browserArray.length > 0) {
        browser.firefox = browserArray[1]
    }
    browserArray = na.match(/chrome\/([\d.]+)/)
    if (browserArray !== null && browserArray.length > 0) {
        browser.chrome = browserArray[1]
    }
    browserArray = na.match(/opera\/.*version\/([\d.]+)/)
    if (browserArray !== null && browserArray.length > 0) {
        browser.opera = browserArray[1]
    }
    browserArray = na.match(/version\/([\d.]+).*safari/)
    if (browserArray !== null && browserArray.length > 0) {
        browser.safari = browserArray[1]
    }

    // 判断浏览器是否使用webkit引擎
    if (/webkit/.test(na)) {
        browser.webkit = na.match(/webkit\/([\d.]+)/)[1]
    } // webkit引擎

    // 判断是否为360浏览器
    if (window.navigator.mimeTypes[40] || !window.navigator.mimeTypes.length) {
        browser.is360 = true
    }

    // 判断IE11
    if (!!window.ActiveXObject || 'ActiveXObject' in window) {
        browser.ie = 11
    }

    return browser
})()

// 2.v-extend-keyup: 键盘按下延迟事件指令
Vue.directive('extendKeyup', {
    bind(el, binding, vnode) {

        let $el = $(el)// 键盘抬起事件
        $el.event2('keyup.extend', function(e) {
            // 清空延时对象
            lazyFn(el, function() {
                let key = e.which
                    // 过滤非法按键符
                if (key === 9 || key === 13 ||
                    key === 16 || key === 20 ||
                    key === 37 || key === 38 ||
                    key === 39 || key === 40 ||
                    key === 91 || key === 186 ||
                    key === 220 || key === 255) {
                    return false
                }
                binding.value(e)
            }, 600)
        }).event2('paste.extend', function() {
            $el.trigger('keyup')
        }).event2('cut.extend', function() {
            $el.trigger('keyup')
        })
    }
})

// 3.v-extend-mousewheel: 鼠标滚轮修正数据兼容指令
Vue.directive('extendMousewheel', {
    bind(el, binding, vnode) {
        let type = browser.firefox ? 'DOMMouseScroll' : 'mousewheel'

        event(el, type, function(event) {
            // 统一为±120，其中正数表示为向上滚动，负数表示向下滚动
            if ('wheelDelta' in event) {
                let delta = event.wheelDelta

                // opera 9x系列的滚动方向与IE保持一致，10后修正
                if (browser.opera && browser.opera < 10) {
                    delta = -delta
                }
                // 由于事件对象的原有属性是只读，添加delta属性来解决兼容问题
                event.delta = Math.round(delta) / 120 // 修正safari的浮点 bug
            } else if ('detail' in event) {
                event.wheelDelta = -event.detail * 40 // 为FF添加wheelDelta
                event.delta = event.wheelDelta / 120 // delta属性
            }
            event.targetElement = event.target || event.srcTarget || event.srcElement
                // 修正IE的this指向
            binding.value(event)
        })
    }
})

// 4.v-extend-number-only: 只能输入整数功能
Vue.directive('extendNumberOnly', function(el) {
    numberOnly(el)
})

// 5.v-extend-max-length: 最大长度截取指令
Vue.directive('extendMaxLength', function(el, binding) {
    let text = binding.value[2]
    if (text === undefined) {
        text = ''
    }
    if (typeof text === 'number') {
        text = text.toString()
    }
    text = text.replace(/(^\s*)|(\s*$)/g, '') // 去掉前后空格
    let maxLength = 0
    let placeholder = ''
        // 0表示不截取
    if (binding.value === 0 || binding.value[0] === 0) {
        return
    }
    if (typeof binding.value === 'number') {
        maxLength = binding.value
        placeholder = '...'
    } else {
        maxLength = binding.value[0]
        placeholder = binding.value[1]
    }
    if (text.length > maxLength) {
        el.title = text
        el.innerHTML = text.substring(0, maxLength) + placeholder
    } else {
        el.innerHTML = text
    }
})

// 6.v-extend-money-format: 金额格式化
Vue.directive('extendMoneyFormat', function(el, binding) {
    el.innerText = moneyFormat(binding.value)
})


Vue.directive('dragList', {
    bind(el, binding, vnode, oldVnode) {
        // 获取拖拽内容头部
        const dialogHeaderEl = el.querySelector('.listHead');
        // 获取拖拽内容整体 这个rrc-dialog是我自己封装的组件 如果使用element的组件应写成.el-dialog
        const dragDom = el
        dialogHeaderEl.style.cursor = 'move';

        // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
        const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null);

        // 鼠标按下事件
        dialogHeaderEl.onmousedown = (e) => {
            // 鼠标按下，计算当前元素距离可视区的距离 (鼠标点击位置距离可视窗口的距离)
            const disX = e.clientX;
            const disY = e.clientY;

            if (e.target.className.baseVal === 'icon16' ||
                e.target.className.baseVal === '') {
                return false
            }


            var d = document.selection;
            if (d && d.empty) d.empty(); //IE
            else if (window.getSelection) window.getSelection().removeAllRanges(); //FF

            // 获取到的值带px 正则匹配替换
            let styL, styT;

            // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
            if (sty.left.includes('%')) {
                styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100);
                styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100);
            } else {
                styL = +sty.left.replace(/\px/g, '');
                styT = +sty.top.replace(/\px/g, '');
            };

            $('.screenfull').css('opacity', 0.5);

            // 鼠标拖拽事件
            document.onmousemove = function(e) {
                // 通过事件委托，计算移动的距离 （开始拖拽至结束拖拽的距离）
                const l = e.clientX - disX;
                const t = e.clientY - disY;

                let finallyL = l + styL
                let finallyT = t + styT

                // console.log(3333, finallyL, finallyT)
                // 移动当前元素
                dragDom.style.left = `${finallyL}px`;
                dragDom.style.top = `${finallyT}px`;

                //将此时的位置传出去
                //binding.value({x:e.pageX,y:e.pageY})
            };

            document.onmouseup = function(e) {
                document.onmousemove = null;
                document.onmouseup = null;
                let headTop = $('.listHead').offset().top
                if (headTop < 90) {
                    headTop = 90
                } else {
                    headTop = headTop + $('.listHead').height()
                }
                $('.screenfull').css('opacity', 1);
                $('.list').animate({ left: 0, top: headTop }, 300)

            };
        }
    }
})


Vue.directive('drag', {
    bind(el, binding, vnode, oldVnode) {
        // 获取拖拽内容头部
        let dialogHeaderEl = null
        if (binding.value != null) {
            dialogHeaderEl = el.querySelector(binding.value);
        } else {
            dialogHeaderEl = el
        }

        // 获取拖拽内容整体 这个rrc-dialog是我自己封装的组件 如果使用element的组件应写成.el-dialog
        const dragDom = el
        dialogHeaderEl.style.cursor = 'move';

        // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
        const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null);

        // 鼠标按下事件
        dialogHeaderEl.onmousedown = (e) => {
            // 鼠标按下，计算当前元素距离可视区的距离 (鼠标点击位置距离可视窗口的距离)
            const disX = e.clientX;
            const disY = e.clientY;

            var d = document.selection;
            if (d && d.empty) d.empty(); //IE
            else if (window.getSelection) window.getSelection().removeAllRanges(); //FF

            // 获取到的值带px 正则匹配替换
            let styL, styT;

            // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
            if (sty.left.includes('%')) {
                styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100);
                styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100);
            } else {
                styL = +sty.left.replace(/\px/g, '');
                styT = +sty.top.replace(/\px/g, '');
            };

            $(el).css('opacity', 0.5);

            // 鼠标拖拽事件
            document.onmousemove = function(e) {
                // 通过事件委托，计算移动的距离 （开始拖拽至结束拖拽的距离）
                const l = e.clientX - disX;
                const t = e.clientY - disY;

                let finallyL = l + styL
                let finallyT = t + styT

                // console.log(3333, finallyL, finallyT)
                // 移动当前元素
                dragDom.style.left = `${finallyL}px`;
                dragDom.style.top = `${finallyT}px`;

                //将此时的位置传出去
                //binding.value({x:e.pageX,y:e.pageY})
            };

            document.onmouseup = function(e) {
                document.onmousemove = null;
                document.onmouseup = null;
                $(el).css('opacity', 1);

                let isMove = false
                let elementTop = $(el).offset().top
                let elementLeft = $(el).offset().left
                console.log(123456, elementLeft, elementTop, $(window).width(), $(el).width())

                // 超出顶部
                if (elementTop < 60) {
                    elementTop = 60
                    isMove = true
                }
                // 超出左边
                if (elementLeft < 0) {
                    elementLeft = 0
                    isMove = true
                }
                // 超出右边
                if ($(window).width() - elementLeft < $(el).width()) {
                    elementLeft = $(window).width() - $(el).width()
                    isMove = true
                }

                if (isMove) {
                    $(el).animate({ left: elementLeft, top: elementTop }, 300)
                }
            };
        }
    }
})



Vue.directive('drag-share', {
    bind(el, binding, vnode, oldVnode) {
        // 获取拖拽内容头部
        let dialogHeaderEl = null
        if (binding.value != null) {
            dialogHeaderEl = el.querySelector(binding.value);
        } else {
            dialogHeaderEl = el
        }

        // 获取拖拽内容整体 这个rrc-dialog是我自己封装的组件 如果使用element的组件应写成.el-dialog
        const dragDom = el
        dialogHeaderEl.style.cursor = 'move';

        // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
        const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null);

        // 鼠标按下事件
        dialogHeaderEl.onmousedown = (e) => {
            // 鼠标按下，计算当前元素距离可视区的距离 (鼠标点击位置距离可视窗口的距离)
            const disX = e.clientX;
            const disY = e.clientY;

            var d = document.selection;
            if (d && d.empty) d.empty(); //IE
            else if (window.getSelection) window.getSelection().removeAllRanges(); //FF

            // 获取到的值带px 正则匹配替换
            let styL, styT;

            // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
            if (sty.left.includes('%')) {
                styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100);
                styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100);
            } else {
                styL = +sty.left.replace(/\px/g, '');
                styT = +sty.top.replace(/\px/g, '');
            };

            $(el).css('opacity', 0.5);

            // 鼠标拖拽事件
            document.onmousemove = function(e) {
                // 通过事件委托，计算移动的距离 （开始拖拽至结束拖拽的距离）
                const l = e.clientX - disX;
                const t = e.clientY - disY;

                let finallyL = l + styL
                let finallyT = t + styT

                // console.log(3333, finallyL, finallyT)
                // 移动当前元素
                dragDom.style.left = `${finallyL}px`;
                dragDom.style.top = `${finallyT}px`;

                //将此时的位置传出去
                //binding.value({x:e.pageX,y:e.pageY})
            };

            document.onmouseup = function(e) {
                document.onmousemove = null;
                document.onmouseup = null;
                $(el).css('opacity', 1);

                let isMove = false
                let elementTop = $(el).offset().top
                let elementLeft = $(el).offset().left
                console.log(123456, parseInt(elementLeft), elementTop, $(window).width(), $(el).width())

                // 超出顶部
                if (elementTop > 0) {
                    elementTop = 0
                    isMove = true
                }
                // 超出左边
                if (elementLeft > 0) {
                    elementLeft = 0
                    isMove = true
                }
                // 超出右边
                else if ($(el).width() + elementLeft < 0) {

                }

                if (isMove) {
                    $(el).animate({ left: elementLeft, top: elementTop }, 300)
                }
            };
        }
    }
})