<!--
* 工具插件
* version: 1.0
* author:  SunAo
* time: 2018-07-13
* compatible: IE10+、firefox、Chrome
* 说明: 对Vue方法扩展的一些常用小方法
* zIndex:
* 10: 局部遮罩,
* 12: 遮罩,
* 13: 弹窗,
# 11: 自动完成组件,
* 13: 提示窗
* 11: 日历
* 11: 下拉菜单
* 11: 返回顶部
* 11: 拖拽范围选择器
* 11: 分割组件
* 10: 伸缩组件
* 9: a257(QQ背景特效)
* 13: loading

方法:
1.$saveLoacl: 保存数据到本地
2.$loadLocal: 读取本地的数据
16.$removeLocal: 删除本地存储
3.$random: 产生min, max范围内的随机数(不包括最大值的数)
4.$parseRadians: 根据角度转换弧度
5.$parseAngle: 根据弧度转换角度
6.$parseLayer: 布局转换
7.$trim: 去掉前后空格
9.$moneyFormat: 金钱格式化方法
10.$findParent: 找到父级对象方法
12.$parseInt: 兼容版parseInt
13.$format: html格式化方法
14.$clone: 深拷贝数组或对象
15.$download: 下载文件方法
20.$stopSelection: 停止文本默认选中事件方法
21.$id: Vue版唯一标示
23.$browser: 浏览器检测对象
29.$startWith: 开头匹配
-->
<template>
  <div class="none"></div>
</template>

<script>
import Vue from "vue";
export default {
  created() {
    // 1.保存数据到本地
    Vue.prototype.$saveLocal = function (key, value) {
      if (typeof value === "string") {
        localStorage.setItem(key, value);
      } else {
        localStorage.setItem(key, JSON.stringify(value));
      }
    };
    // 16.删除本地存储
    Vue.prototype.$removeLocal = function (key) {
      localStorage.removeItem(key);
    };

    // 2.读取本地的数据
    Vue.prototype.$loadLocal = function (key) {
      let content = localStorage.getItem(key);
      if (content !== null ) {

        content = content;
      }
      return content;
    };

    // 3. 产生min, max范围内的随机数(不包括最大值的数) min: 最小值, max: 最大值, isInt: 是否取整
    Vue.prototype.$random = function (min, max, isInt) {
      var number = min + Math.random() * (max - min);
      if (isInt) {
        return parseInt(number);
      }
      return number;
    };

    // 4.根据角度转换弧度 angle: 角度
    Vue.prototype.$parseRadians = function (angle) {
      return (angle * Math.PI) / 180;
    };

    // 5.根据弧度转换角度 radians: 弧度
    Vue.prototype.$parseAngle = function (radians) {
      return (radians * 180) / Math.PI;
    };

    // 6.布局转换,元素在原位换成定位模式,使元素可以移动 elements: 元素元素数组, fn: 每一次循环后的回调函数
    Vue.prototype.$parseLayer = function (elements, fn) {
      elements.forEach(function (element, index) {
        let $this = $(element);
        let position = $this.offset();
        element.movePosition = position;
        fn.call(element, index);
      });

      elements.forEach(function (element, index) {
        let $this = $(element);
        $this.css({
          position: "absolute",
          left: element.movePosition.left,
          top: element.movePosition.top,
        });
      });
    };

    // 7.去掉前后空格
    Vue.prototype.$trim = function (content) {
      return String(content).replace(/(^\s*)|(\s*$)/g, "");
    };
    // 9.金钱格式化
    Vue.prototype.$moneyFormat = function (number) {
      let re = /\d{1,3}(?=(\d{3})+$)/g;
      let n1 = number;
      if (number != null) {
        n1 = number
          .toString()
          .replace(/^(\d+)((\.\d+)?)$/, function (s, s1, s2) {
            return s1.replace(re, "$&,") + s2;
          });
      }
      return n1;
    };

    // 10.找到父级对象方法
    Vue.prototype.$findParent = function (parentName) {
      let parent = this.$parent;
      while (parent.$data[parentName] == null) {
        parent = parent.$parent;
      }
      return parent;
    };

    // 12.兼容版parseInt
    Vue.prototype.$parseInt = function (str) {
      str = String(str);
      return str.charAt(0) === "0" ? parseInt(str, 16) : parseInt(str);
    };

    // 13.html格式化方法
    Vue.prototype.$format = function () {
      try {
        let a = arguments;
        if (a.length === 0) return null;
        let str = a[0];
        for (let i = 1; i < a.length; i++) {
          str = str.replace(new RegExp("\\{" + (i - 1) + "\\}", "gm"), a[i]);
        }
        return str;
      } catch (err) {
        console.log(err.stack);
      }
    };

    // 14.深拷贝数组或对象
    Vue.prototype.$clone = function (data) {
      return JSON.parse(JSON.stringify(data));
    };

    // 15.下载文件方法
    Vue.prototype.$download = function (fileName, data, type) {
      if (!type) {
        type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      }
      let blob = new Blob([data], { type: type });
      const elink = document.createElement("a");
      elink.download = fileName;
      elink.style.display = "none";
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href);
      document.body.removeChild(elink);
    };

    // 20.停止文本默认选中事件方法
    Vue.prototype.$stopSelection = function () {
      var d = document.selection;
      if (d && d.empty) d.empty();
      // IE
      else if (window.getSelection) window.getSelection().removeAllRanges(); // ff
    };

    // 21.Vue版唯一标示
    Vue.prototype.$id = function (prefix) {
      prefix = prefix || "";
      return (
        prefix +
        "_vue_xxxxxxxx_xxxx_xxxx_yxxx_xxxxxxxxxxxx"
          .replace(/[xy]/g, function (c) {
            let r = (Math.random() * 16) | 0;
            let v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
          })
          .toUpperCase()
      );
    };

    Vue.prototype.$layoutID = function (prefix) {
      prefix = prefix || "";
      return (
        prefix +
        "_layout_xxxxxxxx_xxxx_xxxx_yxxx_xxxxxxxxxxxx"
          .replace(/[xy]/g, function (c) {
            let r = (Math.random() * 16) | 0;
            let v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
          })
          .toUpperCase()
      );
    };

    // 23.浏览器检测对象
    Vue.prototype.$browser = (function () {
      let na = navigator.userAgent.toLocaleLowerCase(); // 转成小写
      let browserArray = [];
      let browser = {}; // 保存浏览器信息数组，浏览器名称和版本，长度为2

      // 连续三元操作(模仿jquery)
      browserArray = na.match(/msie ([\d.]+)/);
      if (browserArray !== null && browserArray.length > 0) {
        browser.ie = browserArray[1];
      }
      browserArray = na.match(/firefox\/([\d.]+)/);
      if (browserArray !== null && browserArray.length > 0) {
        browser.firefox = browserArray[1];
      }
      browserArray = na.match(/chrome\/([\d.]+)/);
      if (browserArray !== null && browserArray.length > 0) {
        browser.chrome = browserArray[1];
      }
      browserArray = na.match(/opera\/.*version\/([\d.]+)/);
      if (browserArray !== null && browserArray.length > 0) {
        browser.opera = browserArray[1];
      }
      browserArray = na.match(/version\/([\d.]+).*safari/);
      if (browserArray !== null && browserArray.length > 0) {
        browser.safari = browserArray[1];
      }

      // 判断浏览器是否使用webkit引擎
      if (/webkit/.test(na)) {
        browser.webkit = na.match(/webkit\/([\d.]+)/)[1];
      } // webkit引擎

      // 判断是否为360浏览器
      if (
        window.navigator.mimeTypes[40] ||
        !window.navigator.mimeTypes.length
      ) {
        browser.is360 = true;
      }

      // 判断IE11
      if (!!window.ActiveXObject || "ActiveXObject" in window) {
        browser.ie = 11;
      }

      return browser;
    })();

    // 29.开头匹配
    Vue.prototype.$startWith = function (content, str) {
      let length = str.length;
      let result = content.substring(0, length);
      return result === str;
    };

    let isStop = false;

    Vue.prototype.$monitorTimerStop = function () {
      isStop = true;
    };

    Vue.prototype.$monitorTimerStart = function () {
      isStop = false;
    };
  },
};
</script>
