export default {
  common: {
    title: '100Meet',
    deviceAuthErrorTitle: 'Failed join the meet if you prohibit the permission of microphone or camera',
    deviceAuthErrorMsg: 'You need to open the corresponding permissions in the browser settings to use the 100Meet normally',
    mobileTips: 'Mobile/iPad/pad browser is not supported to join the meet. Please use MAC / Windows computer browser to join the meet',
    errorInfo: 'message',
    errorCode: 'code'
  },
  manager: { // 会前
    langeData: {
      zh: "Chinese",
      en: "English"
    }
  },
  home:{
    title: '100Meet',
    joinMeeting:'Join',
    signIn:'Login',
    signing:'Logining',
    version:'Version：',
    titleTips:'Download 100 MEET',
    quickJoin:'Join meetings quickly and easily',
    back:'< Back',
    personMeet:'Personal Center',
    index:'homepage',
    meetRecord:'meeting record'
  },
  login:{
    newTips:'Chrome, 360, edge, Safari browser recommended, others available',
    newTitle:'The current browser does not support using',
    newDown:'Download the latest Chrome',
    versionTips:'Please upgrade the browser to the latest version',
    versionTitle:'The current browser version does not support',
    downClient:'Download client',
    meetNo:'4-6 digit',
    onlyNo:'4-6 digit',
    week:'week',
    second:'second',
    netFail:'Network exception. Please check and try again',
    openMicrophone:'Turn On Microphone',
    openSpeaker:'Turn On Speaker',
    openCamera:'Turn On Video',
    login:'Join',
    numberTips:'Meeting ID (9-10 digits)',
    tipsName:'Screen Name',
    enterprise:'Enterprise Login',
    self: 'Personal Login',
    mobilePhone:'Verification Code',
    pws:'Enter password',
    mailbox:'Email',
    phone:'Mobile',
    inputPwsd:'Enter password',
    inputCode:'Enter Code',
    findPwsd:'Forgot password',
    noAccount:'No account yet',
    register:'Register new users',
    know:'OK',
    try:'The format of mobile phone number is incorrect. Please check and try again',
    faildCode:'Failed to send verification code',
    successCode:'Verification code sent',
    getCode:'Send Code',
    loginFailed:'Login fail',
    code:'Code',
    password:'Password：',
    enterpriseMailbox:'Enterprise Email',
    tips:'Tips',
    pleasePws:'Please enter the meeting password',
    sure:'OK',
    cancel:'Cancel',
    save:'Save',
    saving: 'Saving',
    meetingPwd:'4-6 digit password',
    leaveMeet:'Leave Meeting',
    startTime:'Start time:',
    ifMain:'If you are the host, please log in and start the meeting',
    meetNumber:'Meeting ID：',
    end:'End Meeting',
    noEnd:'Cancel',
    pleaseTwo:'Wrong meeting password, please re-enter',
    failMeet:'Failed to join the meeting',
    dateTime:'Meeting Time (',
    setMeetPwd:'Set meeting password',
    setMainPwd:'Set host password',
    joinMeetok:'Attendees Can Join Before the Host',
    mute:'Mute Attendees On Entry',
    showTips:'Play Enter Chime',
    relieve:'Allow Attendees to Unmute themselves',
    failEmail:'Wrong email',
    failTextCode:'The verification code is incorrect',
    whomeet:'Schedule',
    bookMeet:"Schedule",
    add:'Add',
    editMeet:'Edit schedule meeting',
    hour:'hour',
    meetPsd:'Meeting Password:',
    meetRequire:'4-6 digit password',
    mainPwd:'Host Password:',
    mainRequire:'4-6 digit host password',
    timeRureire:'Start time should not be earlier than the current time',
    duration:'Duration Time',
    setMeet:'Settings:',
    setMeets:'Meeting Agenda:',
    indexSet:'Setting',
    agenda:'Meeting Agenda:',
    selfNumber:'Personal Meeting ID',
    more:'More',
    edition:'Enterprise Ver',
    free:'Free Ver',
    callService:'Please contact customer service 400-114-9100 for upgrade',
    singleTime:'Duration Per Meeting',
    mintue:'minute',
    mintues:'minutes',
    maxNumber:'Max Attendees',
    system:'Meeting Management System',
    modify:'Modify',
    goto:"go to",
    logOut:'Log out',
    unlimited:'unlimited',
    ybkj:'100Meet',
    meetName:'Name of the meeting',
    hasMeet:'There is an agenda',
    zerominute:'0 minutes',
    shwminute:'15 minutes',
    ssminute:'30 minutes',
    swminute:'45 minutes',
    notRegistered:'Not yet registered',
    complete:'Done',
    surePwd:'Please enter a new password',
    resend:' Resend in N s',
    newPws:'New password',
    surePws:'Confirm password',
    newUser:'Sign Up',
    phoneNumber:'Mobile',
    pwsTips:'Password (composed of 8-16 capital letters, numbers and characters)',
    inputSurePws:'Please enter the password again',
    userName:'Nickname',
    inputCode:'Verification Code',
    onLogin:'Sign Up',
    // okFine:'I have read and agree to Service Agreement and Privacy Policy.',
    okFine:'Agree and abide by',
    termService:'<Service Agreent>',
    goHands:'Jump to page manually>>',
    resetPwd:'Password reset successful!',
    ybGo:'Your Yibai pass has been registered successfully!',
    meetPwd:'Meeting Password',
    meetTheme:'Meeting Topic:',
    bjTime:'Beijing time',
    tokenTime:'Token expired, please login again',
  },
  seeting:{
    inviteInfo:'Invitation information',
    succCopy:' Copy succeeded',
    uploadlog:'Logs',
    help:'Help',
    service:'Service',
    Sun:'Sun',
    Mon:'Mon',
    Tues:'Tues',
    Wed:'Wed',
    Thur:'Thur',
    Fri:'Fri',
    Sat:'Sat',
    year:'Year',
    myMonth:'Month',
    myToday:'Today',
    moring:'AM ',
    afternoon:"PM " ,
    copyINfo:'Copy Invitation',
    edit:'Edit',
    delete:'Delete',
    deleting:'Deleting',
    goMeet:'Start Meeting',
    meetTips:'No meetings',
    lookMeet:"Check out today's upcoming meeting ",
    newMeet:'Start',
    oldMeet:'Historical Meetings',
    mySelfMeet:'Personal Meeting ID',
    no:'No ',
    yy:'Schedule',
    ls:'Historical',
    meet:'Meetings',
    meetEnd:'Ended',
    startMeet:'Meeting Time:',
    endMeet:'End Time:',
    copySuccess:'Invitation information copied successfully',
    ifEnd:'Do you want to end the current meeting？',
    noDelete:'It cannot be restored after deletion',
    deleteMeet:'Delete meeting',
    meetList:'List of participants(',
    Irrecoverable:'It cannot be restored after deletion',
    userName:'Username',
    editSuess:'User name modified successfully',
    oldPwd:'Please enter the original login password',
    diffPwd:'The two passwords are inconsistent',
    editPwdSuccess:'User password modified successfully',
    editMeet:'The meeting was modified successfully',
    useSelf:'Use Personal Meeting ID ',
    now:'Now ',
    countMeet:' meetings',
    afterStart:' minutes before starting ',
    today:'Today',
    immediately:'immediately',
    imMeet:'soon',
    haveHand:'In Progress',
    Cancelled:'Cancelled',
    personMeet:'Personal meeting room',
    fastMeet:' Fast track meeting',
    failMeet:'Failed to start the meeting',
    meetFail:'Booking meeting failed',
    setMeet:'Settings',
    firstPerson:'Owner:',
    joinPerson:'Attendees:',
    excelPerson:'Export',
    joinAgain:'Join Again',
    changeName:'Change the name',
    inputUserName:'Please enter the user name',
    changePwd:'Change Password',
    inputOldPwd:'Please enter the original login password',
    inputNewPwd:'Please enter the new login password',
    inputAgainPwd:'Please enter the new password again'
  },
  meeting: { // 会中
    enterError: 'Failed to enter the meeting. Please try to join again',
    operationFailed: 'The operation failed. Please try again',
    audioNotPermission: 'No microphone permission, please try again after getting the permission in browser',
    videoNotPermission: 'No camera permission right now. Please try again after getting the permission in the browser',
    audioOccupy: 'Microphone device is occupied, please try to quit other software and try again',
    videoOccupy: 'Camera device is occupied, please try to exit other software and try again',
    Sharing:'Screen Sharing',
    allShare: 'Allow 100 MEET to share your screen',
    openShareDeniedHelp: 'Open System Preferences > Security and privacy > Privacy > screen recording, and check 100 MEET to grant access',
    Frame:' Frame',
    frameRate:'Frame rate',
    Resolution:'Resolution',
    bitrate:'Bitrate',
    nets:'Network',
    delay:"delay",
    bandwidth:'Bandwidth',
    shouVideo:'Hide video',
    lookAllVideo:'Show the attendee list',
    selfVideo:'Show my video',
    lookSpeakVideo:'Show the speakers only',
    lookShare:'Show shared screen',
    meetID: 'Meeting ID',
    host: 'Host',
    invite: 'Invitation Link',
    attendee: 'Attendee ID:',
    copy: 'copy',
    encrypted: 'Meeting encrypted',
    network_good: 'Network connection is good',
    net_wrong:'The network connection has been interrupted. It is reconnecting',
    new_poor:'Network connection is poor',
    net_ver:'Network connection is very poor',
    network_look: 'Click to check the network',
    latency: 'Latency',
    packet: 'Packet loss rate',
    recording: 'Recording',
    help: 'Help',
    log: 'Logs',
    kf: 'Service',
    grid: 'Grid View',
    speaker: 'Speaker View',
    grid9: '3 X 3 Grid',
    grid25: '5 X 5 Grid',
    speakerTop: 'List On Top',
    speakerRight: 'List On Right',
    speakerFull: 'Full screen - speaker',
    noChangeLayout: 'Cannot switch to grid view while watching shared content',
    audioMute: 'Mute',
    audioUnMute: 'Unmute',
    log: 'Logs',
    searchPerson:'Search for attendees',
    searchMicrophonePerson:'Search member who turn on the microphone',
    searchCameraPerson:'Search member who turn on the camera',
    searchShareOnPerson:'Search member who turn on the share',
    searchRecordOnPerson:'Search member who turn on the record',
    filter:'filter',
    acquiescence:'acquiescence',
    microphoneOn:'microphone on',
    cameraOn:'camera on',
    shareOn:'share on',
    recordOn:'record on',
    changeName:'Rename',
    setMain:'Make Host',
    goHost:'Make Co-Host',
    removeHost:'Withdraw Co-Host',
    pleaseOpenVideo:'Start Video',
    stopVideo:'Stop Video',
    stopShare:'Stop Sharing',
    putHands:'Lower Hand',
    removeMeet:'Remove',
    noPerson:'No member was found',
    allMute:'Mute All',
    silenceall:'Unmute All',
    allStopHands:'Stop raising your hands',
    getMain:'Reclaim host permissions',
    handsUp:'Raise Hand',
    becomeHost:'Become the host',
    lockMeet:'Lock Meeting',
    noVoiceMeet:'Mute Attendees On Entry',
    tipsVoice:'Play Enter Chime',
    confirmWill:'Confirm',
    moveMeet:'Remove？',
    outMeet:'Remove',
    newVoice:'All the new attendees will be muted',
    inputPwd:'Please enter the host password',
    endShare:'Stop Sharing',
    surShare:'Do you want to stop the current sharing?',
    normalUser:'Ordinary users',
    nohost:'Co-Host',
    Withdrawn:'Withdrawn',
    moderator:'Co-Host authority',
    hostFail:'Failed to withdraw Co-Host',
    beHost:'You have become the co-host',
    goFail:'Failed to make Co-Host',
    removeSuccess:'Attendees removed successfully',
    removeMeetFail:'Attendees removed failed（60026)',
    noVoiceFail:'The host has muted all attendees. Please raise your hand if you want to speak',
    pleaceVoice:'You have requested to unmute everyone',
    me:'Me',
    myChat:'I',
    haveTime:'LeftTime:',
    agenda:'Agenda',
    instability:'The network is unstable. Connecting…',
    pleacenoVoice:'The host asked to unmute you',
    keepQuiet:'Keep mute',
    failMeet:'Failed to join the meeting',
    pleaseOpen:'Please open the microphone / camera permission, refresh the browser, and try to join again',
    abnormality:'Network exception',
    exception:'Network exception. Please check and try again',
    againShare:'Your current sharing has been interrupted. Please try sharing again',
    goMeeting:'Entering the meeting',
    inspectCamera:'The meeting was forced to end. Failed to start the video. Please select another camera in the settings',
    inspectVoice:'Failed to start microphone. Please select another microphone in settings',
    netWorkBad:'Network exception. Please check and try again',
    netWorkTimeout: 'Sorry, the network request timeout. Please try again',
    getOut:'You have been removed by the host',
    overTimeMeet:'Meeting has ended',
    closeMeet:'The meeting was forced to close',
    best:'Best',
    good:'Good',
    commonly:'Commonly',
    noGood:'Bad',
    noBest:'very bad',
    notAvailable:'Not available',
    becomeGoHost:'You have become a co host',
    backModerator:'The host has withdrawn your co host rights',
    loging:'Uploading log, please wait',
    logSuccess:'Upload log successfully',
    logFail:'Failed to upload log, please try again',
    becomeMain:'You have become the host',
    mainNoVoice:'You have been muted by the host',
    allNoVoice: 'Everyone has been muted by the host',
    mainStopShare:'The host has stopped your sharing',
    closedVideo:"You've been turned off by the host",
    giveYou:'The rights of the host have been transferred to you',
    lijiEnd:"Your meeting will be ended in",
    noMembership:"You have joined other devices and the current device has been removed from the meeting",
    inMinutes:'minutes',
    lockMeeting:'No new attendees can join this meeting once locked',
    okMeet:'New attendees can join this meeting once unlocked',
    selectMicrophone:'Select microphone',
    closeMicrophone:'Mute',
    openMicrophone:'Unmute',
    selectSpeakers:'Select speaker',
    closeSpeakers:'Turn off the speaker',
    openSpeakers:'Turn on the speaker',
    openVideo:'Start Video',
    chooseVideo:'Select camera',
    closeVideo:'Turn off the camera',
    onepCamera:'Turn on the camera',
    share:'Share',
    sharingRights:'Screen Sharing Permissions',
    onlyMain:'Only the host can share',
    allPeople:'All attendees can share',
    stopThisShare:'Stop screen sharing',
    isStopShare:'Do you want to stop the current sharing？',
    stopSharing:'Someone is currently sharing，Do you want to stop the current sharing?',
    noStop:'Cancel',
    isStop:'Stop',
    youShare:'You are sharing',
    hostShare:'Host sharing',
    haveShare:'Someone is currently sharing',
    getShareIng:'Please apply to the host for sharing permission',
    youAreShare:'You are sharing',
    shareTop:'share paused',
    lookWhoShare:"You're looking at ",
    shares:' shares',
    lookBIg:'View shared screen size',
    windowOk:'Adaptive window',
    bfb:'100%（Actual size）',
    unlockScreen:'Unlock',
    lockScreen:'Lock screen',
    screenUnlock:'Pin Video',
    unLocking:'The screen is locked. Click the upper left corner to unlock',
    startRecording:'Record',
    RecordingRights:'Recording Permissions',
    hostRecordingOnly:'Only the host can record',
    allRecord:'Allow all attendees to record',
    getRecord:'Please apply to the host for recording permission first',
    recordCoding:'Please download the recording function for Windows or macOS client of Yibai conference Download client',
    handsIng:'raising hands',
    member:'Attendees',
    ManagingMembers:'Attendees',
    ren:'people',
    geren:'personal',
    chat:'Chat',
    chatList:'Chat',
    toEveryone:'send it to all',
    sendTo:'Send to',
    PrivateChat:'(Private chat)',
    aite:'Someone @ you, click View>>',
    eitherMe:'Me',
    toSend:'Sent to:',
    allPeople:'All',
    allPeoples:'All attendees',
    inputChat:'Please input chat text',
    send:'Send',
    goRoom:'You were kicked out of the room',
    imgTipsOne:'Upload image can only be JPG or PNG format!',
    imgTipsTwo:'The size of the uploaded image cannot exceed 2MB!',
    wordLimt:'Chat room input content can not exceed 300 words at most',
    words:'Chat content',
    endMeetings:'End Meeting',
    leaveMeetings:'Leave Meeting',
    countDown:'Countdown',
    timerDown:'Timer',
    countDownTime:'Countdown:',
    displayLocation:'Display location:',
    left:'Top Left Corner',
    right:'Top Right Corner',
    littlePeople:'Unable to switch to grid view as the meeting is less than 2 attendees',
    timedOut:'Timed out',
  },
  setUp:{
    register:'Your Yibai pass has been registered successfully !',
    aweek:'a week',
    tweek:'Two weeks',
    thweek:'Three weeks',
    fweek:'Four weeks',
    realTime:'real time',
    oneDay:'1 day',
    sevenDay:'7 days',
    thirdyDay:'30 days',
    nineDay:'90 days',
    season:'season',
    sDay:'Last three days',
    weekDay:'Nearly a week',
    oneMonth:'Last month',
    yMonth:'January',
    tMontn:'February',
    thrMonth:'March',
    fMonth:'April',
    fivMonth:'May',
    sixMonth:'June',
    sevenMonth:'July',
    eigMonth:'August',
    nineMonth:'September',
    shiMonth:'October',  
    eleMonth:'November',
    tlvMonth:'December',
    placTime:'Please select a date',
    reset:'Reset',
    set:'Settings',
    close:'Close',
    tuiTest:'Quit testing',
    tobeTested:'Pending test',
    seeting:'Setting',
    second:'frames-per-second',
    language:'Language Settings:',
    change:'Select',
    netDetection:'Network Test',
    pleaseDec:'Please click Start Testing',
    start:'Start Testing',
    networkStatus:'The network condition is ',
    netOk:'Complete the network detection, the current network condition is ',
    againDec:'Test Again',
    netConnection:'Network Connectivity',
    serverConnection:'Server Connectivity',
    mediaConnection:'Media Server Connectivity',
    ok:'Normal',
    inNet:'Testing',
    overTime:'Timed out',
  //检测出N个异常问题，请检查网络连接
  // N abnormal problems detected, please check the network
    Detected:'Detected',
    someQuestion:'abnormal problems,please check the network',
    connectionTime:'Time Taken for Connection',
    submitFeedback: 'Send Feedback',
    thanks:'We welcome your ideas, requests or comments',
    contactInfo:'Contact',
    call:'Illegal content reporting hotline:991 039 485',
    cameraOK:'If you can see the camera image, it means that the camera is normal',
    camera:'Camera',
    myVideo:'My Video',
    microphone:'Microphone',
    voice:'Volume',
    microphoneOk:'When speaking to the microphone, if you can see the volume fluctuation, the microphone is normal',
    speaker:'Speaker',
    stopDetection:'Stop speaker',
    testLoudspeaker:'Test speaker',
    voiceOk:'Music playing...',
    normal:'General',
    audio:'Audio',
    video:'Video',
    startMeet:'Enter full screen automatically when starting or joining a meeting',
    fullScreen:'Enter full screen automatically when viewing screen shared by others',
    showTime:'Show my connected time',
    limit:'Limit your screen share to',
    useVideo:'Enable mirror effect',
    highVideo:'Enable HD',
    useHardware:'Enable hardware acceleration',
    detecting:'Testing',
    abnormal:'Exception',
    poor:'poor',
    range:'Range',
    mobilePhone:'Mobile/Email',
    stopVoice:'Click stop test to stop playing the current test audio',
    addSpeack:'Click on the test speaker to make sure you can hear others',
    lookIng:'In Preview',
    closeCamrea:'Camera off',
  },
  reg:{
     //新增文字提示
     emptyMeetName:'Meeting name cannot be empty',
     huiyiPwd:'Please enter the conference password',
    countTime:'Please enter an integer from 1 to 1000',
    nameEmpty:'User name cannot be empty',
    emptyPwd:'Please check whether the input password is empty',
    lengthPwd:'Please check whether the length of the input password is 8-16 bits',
    somePwd:'Please check whether the new password is consistent with the two input',
     // 原有正则校验
    regEmpty: " Cannot be empty",
    regMaxLength: "最大长度不能大于",
    regMaxLengthByArray: "单个词最大长度不能大于",
    regMaxLengthByArray2: "单个词最大长度不能大于",
    regSpecial: "有非法字符",
    regSpecial2: "有非法字符",
    regDate: "日期格式不正确",
    regDateTime: "日期格式不正确",
    regDateTimeNoSecond: "日期格式不正确" + "(不带秒)",
    regLimitDate: "日期只能限制在{0}天内",
    regCompareDate: "开始时间不能大于结束时间",
    regCompareNumber: "开始数字不能大于结束数字",
    regCompareNumber2: "开始数字不能大于结束数字",
    regMobile: "格式不正确",
    regEmail: "格式不正确",
    regNumber: "只能填写数字",
    regNumber2: "只能填写正负整数",
    regNumber4: "只能填写正数",
    regNumber5: "只能填写正整数",
    regNumber7: "只能填写正整数",//只能填写正整数和包含0的数字
    regBetwwenLength: "The length must be between {0} and {1}",
    regBetwwenLength2: "长度必须在{0}到{1}之间",
    regNumberBetwwenLength: "范围必须在{0}到{1}之间,包含 ({0}或{1})",
    regUrl: "格式不正确",
    regIP: "格式不正确",
    regNorm: "只能输入数字、字母、下划线、小数点",
    regNorm2: "只能输入数字、字母、横杠",
    regNorm3: "只能输入数字、字母",
    regNorm4: "只能输入中文、字母",
    regNorm5: "只能输入数字、字母、中文",
    regNorm6: "只能输入数字、字母、(、)、-",
    regNorm7: "只能输入数字、字母、下划线、-",
    regNorm8: "只能输入数字、英文逗号",
    regCommon: "只能输入数字、字母、中文、下划线",
    regCommonWords: "只能输入数字、字母、中文、韩文、日文、下划线",
    regCommonPwd: "密码输入错误",
    regKorean: "只能输入数字、字母、中文、韩文、下划线",
    regFilter: "有不良信息",
    regKeyword: "不能是关键词",
    regKeyword2: "不是是关键词",
    regStartsWithNoNumber: "不能以数字开头",
    regNoLetter: "不能全是字母",
    regNoNumber: "不能全是数字",
    regSelect: "请选择",
    regCheckbox: "请选择",
    regEquals: "不一致",
    regNotEquals: "不能相等",
  }
}