export const strMiddleEllipsis = (str, maxStrLen, leftLen, rightLen) => {
    if (!str) {
        return ''
    }

    const strLen = str.length
    if (strLen < maxStrLen) {
        return  str
    }

    const leftStr = str.substring(0, leftLen)
    const rightIndex = strLen - rightLen


    return `${leftStr}...${str.substring(rightIndex, strLen)}`
}

export const strToBoolean = (str, defaultBol) => {
    if (!str) {
        return defaultBol
    }

    return str === 'true' ? true : false
}

export const strFormat = function(str) {

    if (arguments.length < 2) {
        return str
    }

    const args = Array.prototype.slice.call(arguments, 1)

    for (let key in args) {
        let value = args[key]
        if (undefined != value) {
            str = str.replaceAll("{" + key + "}",value)
        }
    }
    return str
}