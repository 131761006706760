import Base from "../BasePlugin";

import { isPhone } from '../../utils/check'

import { signalUrl} from "../../config/index";

import { fetchMeetControl } from '../../api/meet'
import {
  CONTROL_COMMAND,
  ENTERROOM_ERR_CODE,
  VIDEO_INSTABLE_CODE,
  VIDEO_STABLE_CODE,
  NO_CAMERA_GRANT_CODE,
  NO_MIC_GRANT_CODE,

  AUDIO_INSTABLE_CODE,
  AUDIO_STABLE_CODE,
  ERR_CODEC_UNSUPPORT,
} from "../../constant/control";


 export default class RTC extends Base {
  //  roomClient: YB_RTC;
   constructor(app, options) {
     super(app, options);
    //  this.app = app;
     this.roomClient = app.roomClient;
     this.roomParams = null; // 房间信息

     this.events = []; // 向上回调的事件集合
     this.initEvents();

     this.cameras = []; //摄像头列表
    //  this.localVideoEl = null;
     this.isPhone = isPhone();

     this.timer = null;
   }


  /**
   * 初始化监听事件
   */
  initEvents() {
    this.errorMsg()
    this.onEnteredMeeting()
    this.onExitMeeting()
    this.onRemoteUserEnterMeeting()
    this.onRemoteUserLeaveMeeting()
    this.onQueryUserList()
    this.onUserVideoAvailable()
    this.onUserAudioAvailable()
    this.userSpeaking()
    this.onUserShareAvailable()
    this.onUserVoiceVolume()
    this.onDeviceChange()
    this.onConnectionLost()
    this.onTryToReconnect()
    this.onConnectionRecovered()
    this.onScreenCaptureStarted()
    this.onScreenCapturePaused()
    this.onScreenCaptureResumed()
    this.onScreenCaptureStoped()
    this.onDisplayNameChanged()
    // this.onMeetingControl()
    this.onMeetingControlResult()
    this.onActiveSpeakers()
    this.onRenderVideoFrame()
  }

  /** 实例方法 -- start */

	//设置本地视频属性 需要在打开摄像头之前设置
	setVideoEncoderParam(encoderParam) {

    return this.roomClient.setVideoEncoderParam(encoderParam)
	}

	//设置本地音频的属性 需要在打开音频前设置 默认为standard
	// 音频 Profile	采样率	声道	码率(kbps)
	// standard	48000	单声道	40
	// high	48000	单声道	128
	setAudioProfile(profile) {
    return this.roomClient.setAudioProfile(profile)
	}
  //静音/取消静音本地音频
  muteLocalAudio(mute) {
    return this.roomClient.muteLocalAudio(mute)
  }
	// 设置远端图像的渲染模式
	setRemoteViewFillMode(userId, mode) {
    return this.roomClient.setRemoteViewFillMode(userId, mode)
	}

	// 设置音频质量级别
	setAudioQualityLevel(quality) {
    return this.roomClient.setAudioQualityLevel(quality)
	}

	getSendTransportLocalStats() {
    return this.roomClient.getSendTransportLocalStats()
	}
  //暂停/恢复接收所有的远端视频流

	muteAllRemoteViews() {
    return this.roomClient.muteAllRemoteViews()
	}

  /**
   * 开启并加入新会议
   * meetingTitle: 会议主题
   * usePersonalMeetingNo: 是否使用个人会议号 0-否 1-是
   * openMic: 是否开启麦克风 0-否 1-是
   * openSpeaker: 是否开启扬声器 0-否 1-是
   * openCamera: 是否开启摄像头 0-否 1-是
  */
  startMeeting(options = {}) {

    let default_options = {
      serverUrl: signalUrl,
      sdkAppId: 1,
      userSig: "",
      maxRetryTime: 30 * 1000,
    }

    default_options = Object.assign(default_options, options)

    this.roomParams = default_options;

    return this.roomClient.enterRoom(default_options)
  }

  /**
   * 加入会议
   * displayName: 显示的名称
   * meetingNo: 会议号
   * meetingPassword: 会议密码
   */
  joinMeeting(options) {
    return this.roomClient.enterRoom(options)
  }

  /**
   * 离开会议
   * bSilence: 是否是安静的离开，为 true时则不会通知RTC服务器（不向RTC服务器发送leave）
   */
  leaveMeeting(isLeave = false) {
    this.destroy();
    console.error('需要安静离开',isLeave)
    return this.roomClient.exitRoom(isLeave);
  }

  /**
   * 结束会议
   * 会通过onMeetingControlResult收到回调通知
   */
  endMeeting() {
    this.destroy();

    // 清空 RTCSDK 数据
    this.roomClient.exitRoom(true);

    let data = { command: CONTROL_COMMAND.END_MEET };

    let meetConfig = {
        roomId: this.roomParams.roomId,
        peerId: this.roomParams.userId
    };

    return fetchMeetControl(data, meetConfig);

  }

  /**
   * 开启本地音频
   * 有错误发生会触发onError或onWarning
   * @params mute: 开始本端音频通道 是否静音
   */
  startLocalAudio(mute) {
    return this.roomClient.startLocalAudio(mute);
  }

  /**
   * 获取音量leave
   */
   getAudioLevels() {
    return this.roomClient.getAudioLevels();
  }


  /**
   * 停止本地音频
   * 有错误发生会触发onError或onWarning
   */
  stopLocalAudio() {
    return this.roomClient.stopLocalAudio();
  }

  //todo setAudioPlayoutVolume
  startLocalSpeaker(elements, val) {
    // 0的话代表关闭扬声器
    return this.roomClient.setAudioPlayoutVolume(elements, val);
  }



  /**
   * 开启本地视频预览（但不推流）
   * 有错误发生会触发onError或onWarning
   */
  startLocalPreview(width, height, deviceId, elementId) {
    let video = document.getElementById(elementId)
    if (!video) return
    return new Promise(async (resolve, reject) => {
      try {
        const configObject = {
          width: width,
          height: height,
          deviceId: { ideal: deviceId }
        }
        const mediaStream = await navigator.mediaDevices.getUserMedia({ video : configObject})
        video.srcObject = mediaStream
        video.play()
        resolve(mediaStream)
      } catch (error) {
        reject(`start local camera error:${error}`)
      }
    })
  }


  /**
   * 停止本地视频预览
   * 有错误发生会触发onError或onWarning
   */
  stopLocalPreview(elementId) {
    let videoElement = document.getElementById(elementId)
    if (videoElement) {
        videoElement.src = ""
        videoElement.srcObject = null
    }
  }

  /**
   * 开始本地视频，同时推送视频流
   * 有错误发生会触发onError或onWarning
   */
   startLocalVideo(videoEl) {


    // this.localVideoEl = videoEl;
    return this.roomClient.startLocalPreview(videoEl);

  }

  cloneLocalVideo(videoEl) {
    return this.roomClient.enableWebcam(videoEl);
  }

  /**
   * 停止本地视频推流
   * 有错误发生会触发onError或onWarning
   */
  stopLocalVideo() {
    return this.roomClient.stopLocalPreview();
  }


  /**
   * 开始本地共享
   * 有错误发生会触发onError或onWarning
   */
  startScreenSharing(qrtcView, encParam) {
    return this.roomClient.startScreenCapture(qrtcView, encParam)
  }


  /**
   * 停止本地共享
   * 有错误发生会触发onError或onWarning
   */
  stopScreenSharing() {
    return this.roomClient.stopScreenCapture();
  }


  /**
   * 暂停本地共享
   * 有错误发生会触发onError或onWarning
   */
  pauseScreenSharing() {
    return this.roomClient.muteScreenCapture();
  }


  /**
   * 恢复本地共享
   * 有错误发生会触发onError或onWarning
   */
  resumeScreenSharing() {
    return this.roomClient.resumeScreenCapture();
  }



  /**
   * 远端用户有视频流，开始拉流
   * 有错误发生会触发onError或onWarning
   */
  startRemoteVideo(userId,element) {
    return this.roomClient.startRemoteVideo(userId,element)
  }


  /**
   * 停止远端用户的视频流
   * 有错误发生会触发onError或onWarning
   */
  stopRemoteVideo(userId) {
    return this.roomClient.stopRemoteVideo(userId);
  }


  /**
   * 远端用户有音频流 ，开始拉音频流
   * 有错误发生会触发onError或onWarning
   */
  startRemoteAudio(userId, element) {
    return this.roomClient.startRemoteAudio(userId, element);
  }


  /**
   * 停止远端用户的音频流
   * 有错误发生会触发onError或onWarning
   */
  stopRemoteAudio(userId) {
    return this.roomClient.stopRemoteAudio(userId);
  }


  /**
   * 开始拉共享流
   * 有错误发生会触发onError或onWarning
   */
  startRemoteSharing(userId,element) {
    return this.roomClient.startRemoteShare(userId,element)
  }

  /**
   * 停止远端用户的共享流
   * 有错误发生会触发onError或onWarning
   */
  stopRemoteSharing(userId) {
    return this.roomClient.stopRemoteShare(userId)
  }




  /**
   * 更改本地显示名 自己改名
   * @param displayName: 要修改到的新的名称
   * 改名后，房间内的其它人会收到onDisplayNameChanged的通知
   * 有错误发生会触发onError或onWarning
   */
  changeLocalDisplayName(displayName) {
    return this.roomClient.changeDisplayName(displayName);
  }


  /**
   * 设置当前摄像头
   * @param {*} deviceId 设备ID
   */
   async setCurrentCameraDevice(deviceId) {

    if(!deviceId) throw new Error('setCurrentCameraDevice deviceId is required ！')

    return this.roomClient.setCurrentCameraDevice(deviceId)

  }

  /**
   * 初始化摄像头
   * 强制前置摄像头
  */
  _initCameras() {

    (async () => {

      try {
        const cameras = await this.roomClient.getCameraDeviceList();

        this.app.safeEmit('sdkLogReport', `[web sdk] cameras: ${JSON.stringify(cameras)}`);
        console.log(`[web sdk] cameras: ${JSON.stringify(cameras)}`);
        this.cameras = cameras

        this.setCurrentCameraDevice(cameras[0].deviceId)
      } catch (error) {

      }

    })()


  }
  /**
   * 翻转摄像头
   * 有错误发生会触发onError或onWarning
  */
  async switchCamera() {

    const cameras = this.cameras;
    let other = null
    if(cameras.length < 2) {
      throw new Error('switchCamera no more camera')
    }

    let currentCamera = this.roomClient.getCurrentCamera();
    if (!currentCamera) {
      currentCamera = cameras[0].deviceId;// 取不到的话，取第一个
    }

    other = cameras.filter(item => item.deviceId != currentCamera)[0];
    // 如果大于 2 则去
    if(cameras.length > 1) {
      if (currentCamera == 'environment') {
        other = {
          deviceId: cameras[0].deviceId,
        }
      } else {
        other = {
          deviceId: 'environment',
        }
      }
    }

    try {
      this.app.safeEmit('sdkLogReport',
      `[web sdk] switchCamera: currentCamera ${currentCamera}, other ${other.deviceId}`);

      await this.setCurrentCameraDevice(other.deviceId);
      return

    } catch (error) {
      throw new Error(error)
    }


  }

  /** 实例方法 -- end */


  /** events -- start */


  /**
   * 开启本地预览 纯预览
   * @param HTMLVideoElement: dom
   */
  startLocalPreviewOnly() {
    return this.roomClient.startLocalPreviewOnly(...arguments);
  }
  startLocalPreviewOnly2() {
    return this.roomClient.startLocalPreviewOnly2(...arguments);
  }


  /**
   * 关闭本地预览
   */
  stopLocalPreviewOnly() {
    return this.roomClient.stopLocalPreviewOnly();
  }


  /**
   * 获取美颜模块
   */
  getBeautyManager(type) {
    return this.roomClient.getBeautyManager(type);
  }


  /**
   * SDK异常码
   *
   */
   errorMsg() {
     /***** --------------- 本端网络状态 ------------- *********/

    //注册web-sdk 连接超时 回调
    this.roomClient.on('connectionTimeOut',()=>{
      this._beforeSafeEmit('connectionTimeOut');
    })

    // 网络状态
    this.roomClient.on('networkQuality',(qualityInfo)=>{
      this._beforeSafeEmit('networkQuality', qualityInfo);
    })
    //注册web-sdk 连接断开通知
    this.roomClient.on('disconnected',()=>{
      this._beforeSafeEmit('disconnected');
    })
    //注册web-sdk 重连中
    this.roomClient.on('websocketMayDisconnected',()=>{
      this._beforeSafeEmit('websocketMayDisconnected');
    })
    // 本端连接恢复正常
    this.roomClient.on('websocketStillAlive',()=>{
      this._beforeSafeEmit('websocketStillAlive');
    })

    /**** ------------------ 注册web-sdk错误异常处理回调 ------------------****/
    this.roomClient.on('error',(error)=>{
      this._beforeSafeEmit('error',error);
    })


    this.roomClient.on('errorMsg',({ code, errorData })=>{
      this.app.safeEmit('sdkLogReport', `[web sdk] errorMsg: code -- ${code}`);

      code != '80001005' && console.error('sdk errorMsg', code, errorData);

      /***** ----------- SDK所有的errorMsg透传 --------- ***********/
      this._beforeSafeEmit('errorMsg', { code, errorData });


      /***** ----------- 入会失败上抛 --------- ***********/
      if(ENTERROOM_ERR_CODE.includes(code)) {
        this._beforeSafeEmit('enter-room-error', errorData.userId);
        return
      }
      /***** ----------- 本端摄像头无权限上抛 --------- ***********/
      if(NO_CAMERA_GRANT_CODE.includes(code)) {
        this._beforeSafeEmit('no-camera-grant');
        return
      }

      /***** ----------- 本端麦克风无权限上抛 --------- ***********/
      if(NO_MIC_GRANT_CODE.includes(code)) {
        this._beforeSafeEmit('no-mic-grant');
        return
      }

      /***** ----------- 订阅视频失败上抛 --------- ***********/
      /***** ----------- 订阅视频不稳定上抛 --------- ***********/
      if(VIDEO_INSTABLE_CODE.includes(code)) {
        this._beforeSafeEmit('video-instable', errorData.userId, errorData.share);
        return
      }
      /***** ----------- 订阅视频恢复稳定上抛 --------- ***********/
      if(VIDEO_STABLE_CODE.includes(code)) {
        this._beforeSafeEmit('video-stable', errorData.userId, errorData.share);
        return
      }
      /***** ----------- 订阅音频不稳定上抛 --------- ***********/
      if(AUDIO_INSTABLE_CODE.includes(code)) {
        this._beforeSafeEmit('audio-instable', errorData.userId);
        return
      }

      /***** ----------- 订阅音频稳定上抛 --------- ***********/
      if(AUDIO_STABLE_CODE.includes(code)) {
        this._beforeSafeEmit('audio-stable', errorData.userId);
        return
      }

      /***** ----------- 解码能力不支持 --------- ***********/
      if(ERR_CODEC_UNSUPPORT.includes(code)) {
        const share = errorData.kind == 'share';// 是否共享流

        this._beforeSafeEmit('codec-unsupport', errorData.userId, share);
        return
      }

      /***** ------------- 推视频流异常 ----------- ********/

      /***** ------------- 推音频流异常 ----------- ********/



    })


    /**** --------------- 会控通知回调 ---------------****/
    this.roomClient.on('customCommand',(data)=>{
      this._beforeSafeEmit('customCommand', data);
    })


    /**** --------------- 注册web-sdk log 回调 -----------------****/
    this.roomClient.on('sdkLogReport',(message)=>{
      this._beforeSafeEmit('sdkLogReport', message);
    })

  }




  /**
   * 入会结果回调
   *
   */
  onEnteredMeeting() {
    this.roomClient.on('enterRoom',(data)=>{
      if(this.isPhone){ //只有移动端需要
        this._initCameras(); // 初始化摄像头强制 前置
      }
      this._beforeSafeEmit('onEnteredMeeting', data.selfTimeMs);
    })
  }


  /**
   * 离会结果回调
   *
   */
  onExitMeeting() {
    this.roomClient.on('exitRoom',(data)=>{
      this._beforeSafeEmit('onExitMeeting',data);
        setTimeout(() => this.destroy(), 50);
      })
  }

  onVideoMute() {
    this.roomClient.on('localVideoTrackMute', (data) => {
      this._beforeSafeEmit('localVideoTrackMute', data);
    })

    this.roomClient.on('localVideoTrackUnmute', (data) => {
      this._beforeSafeEmit('localVideoTrackumMute', data);
    })
  }

  /**
   * 有用户加入当前房间
   * 注意 onRemoteUserEnterMeeting 和 onRemoteUserLeaveMeeting 只适用于维护当前房间里的“成员列表”
   * @param userInfo: 用户信息
   */
  onRemoteUserEnterMeeting() {
    this.roomClient.on('remoteUserEnterRoom',(userInfo)=>{
      this._beforeSafeEmit('onRemoteUserEnterMeeting',userInfo);
    })
  }


  /**
   * queryUserList
   */
  onQueryUserList() {
    this.roomClient.on('queryUserList',(userInfo)=>{
      this._beforeSafeEmit('onQueryUserList',userInfo);
    })
  }
  

  /**
   * 有用户离开当前房间
   * @param userId: 用户标识
   * @param  reason: 离开原因
   * - LEAVEROOM_NORMAL: 表示用户主动退出房间，
   * - LEAVEROOM_TIMEOUT: 表示用户超时退出，
   * - LEAVEROOM_KICKED: 表示被踢出房间。
   */
  onRemoteUserLeaveMeeting() {
    this.roomClient.on('remoteUserLeaveRoom',(userInfo)=>{
      this._beforeSafeEmit('onRemoteUserLeaveMeeting', userInfo);
    })
  }

  /**
   * 远端用户是否存在可播放的主路画面(一般用于摄像头)
   * 远端有人推视频流
   * 当您收到 onUserVideoAvailable(userId, YES) 通知时，表示该路画面已经有可用的视频数据帧到达。
   * 此时，您需要调用 startRemoteView(userId) 接口加载该用户的远程画面。
   * 然后，您还会收到名为 onFirstVideoFrame(userId) 的首帧画面渲染回调。
   *
   * 当您收到 onUserVideoAvailable(userId, NO) 通知时，表示该路远程画面已被关闭，
   * 可能由于该用户调用了 muteLocalVideo() 或 stopLocalPreview()。
   * 当您收到 onUserVideoAvailable(userId, NO) 通知时，可以做一些上层APP的清理工作, 可以调/也可以不再调用muteRemoteView, stopRemoteView了
   * @param userId: 用户标识
   * @param available: 画面是否开启
   *      - true: 是
   *      - false: 用户关闭了视频
   */
  onUserVideoAvailable() {
    this.roomClient.on('userVideoAvailable',(videoInfo)=>{
      this._beforeSafeEmit('onUserVideoAvailable',videoInfo);
    })
  }

  /**
    * 远端用户是否存在可播放的音频数据
    * 远端有人推音频流
    * @param userId: 用户标识
    * @param available: 声音是否开启
   */
  onUserAudioAvailable() {
    this.roomClient.on('userAudioAvailable',(audioInfo)=>{
      console.log('远端音频状态监听回调step1----',audioInfo)
      this._beforeSafeEmit('onUserAudioAvailable',audioInfo);
    })
  }

  /**
    * 远端用户有人讲话
    * @res speakingInfo: 讲话人信息
    * @param userId: 用户标识
   */
  userSpeaking() {
    this.roomClient.on('userSpeaking',(speakingInfo)=>{
      this._beforeSafeEmit('userSpeaking',speakingInfo);
    })
  }

  /**
    * 远端用户是否存在可播放的辅路画面( 一般用于屏幕分享)
    * 远程有人开始或者停止共享流
    * 显示辅路画面使用的函数是 startRemoteShareView() 而非 startRemoteView()
    *
    * @param userId: 用户标识
    * @param available: 屏幕分享是否有效，结合reason会可以知道是start, resume, pause, stop状态
    * @param reason: 响应的原因, 上层APP可以视情况处理，也可以忽略
    *  - AVSTATE_START: 开始
    *  - AVSTATE_RESUME: 从暂停中恢复
    *  - AVSTATE_PAUSE: 从正常状态进入暂停
    *  - AVSTATE_STOP: 停止
  */
  onUserShareAvailable() {
    this.roomClient.on('userShareAvailable', (shareInfo)=>{
      this._beforeSafeEmit('onUserShareAvailable', shareInfo);
    })

    this.roomClient.on('userShareAudioAvailable', async (shareInfo)=>{
      const { userId, available, paused ,status} = shareInfo;
      console.log('[shareAudio] 当前有共享音频需要订阅', shareInfo)
      let audioEl = document.querySelector('#share_audio');
      if(!audioEl) {
        audioEl = document.createElement('audio');
        audioEl.setAttribute('id', 'share_audio');
        audioEl.setAttribute('class', 'audioPlayoutVolumeItem');
        document.body.appendChild(audioEl)
      }
      // 先静音订阅
      audioEl.volume = 0;

      if(available) {
        try {
          if(status == 1) {
            console.log('[shareAudio] 当前有共享音频需要订阅-> start')
            await this.roomClient.startRemoteShareAudio(userId, audioEl)
            console.log('[shareAudio] 当前有共享音频需要订阅-> end')
          }
          // Paused -> 远端共享暂停了！
          if(status == 3) {
            console.log('[shareAudio] 当前有共享音频 暂停-> start')
            await this.roomClient.muteRemoteShareAudio(userId)
          }
          // resume -> 远端共享恢复了！
          if(status == 4) {
            console.log('[shareAudio] 当前有共享音频 恢复-> start')
            await this.roomClient.resumeRemoteShareAudio(userId)
          }
        } catch(e) {
          console.error('[shareAudio] 当前共享音频订阅错误', e)
          return;
        }

        audioEl.addEventListener('pause', () => {
          console.log('audio pause userId', userId);
          setTimeout(() => {
            console.log('pause after start play');
            audioEl.play();
          },50)
        })

        setTimeout(() => {
          try {
            let volume = 0, sinkId;
            const status = document.querySelector('#speakerHiddenStatus').value;
            volume = document.querySelector('#speakerHiddenStatus').value;
            sinkId = document.querySelector('#speakerDeviceId').value;
            // volume = document.querySelector('audio').volume;
            // sinkId = document.querySelector('audio').sinkId;
            audioEl.volume = status == 0 ? 0 : volume;
            audioEl.setSinkId(sinkId);
            // 音量变化后， 自动校准是否跟当前音量相等。 不等于 设置为当前音量
            // audioEl.addEventListener('volumechange', (e) =>{
            //   if(status != 0) {
            //     const val = e.target.volume;
            //     if(volume != val) {
            //       audioEl.volume = volume;
            //     }
            //   } else {
            //     audioEl.volume = 0;
            //   }
            // })
          } catch(e) {
            console.error('设置共享组件的 音频失败')
          }
        }, 600);
      } else {
        this.roomClient.stopRemoteShareAudio(userId)
      }
      // this._beforeSafeEmit('onUserShareAudioAvailable', shareInfo);
    })
  }

  /**
    * 用于提示音量大小的回调，包括每个 userId 的音量和远端总音量
    * 您可以通过调用 TRTCCloud 中的 enableAudioVolumeEvaluation 接口来开关这个回调或者设置它的触发间隔。
    * 需要注意的是，调用 enableAudioVolumeEvaluation 开启音量回调后，无论频道内是否有人说话，都会按设置的时间间隔调用这个回调，
    * 如果没有人说话，则 userVolumes 为空，totalVolume 为0。
    *  userVolumes 中 userId 为空字符串（""）时表示自己的音量，userVolumes 内仅包含正在说话（音量不为0）的用户音量信息。
    * @param userVolumes: 正在说话的房间成员的音量，取值范围0 - 100。
    * @param userVolumesCount:  userVolumes数据大小
    * @param totalVolume: 总音量, 取值范围0 - 100。
  */
  onUserVoiceVolume() {
    this.roomClient.on('xxxxx',()=>{
      this._beforeSafeEmit('onUserVoiceVolume');
    })
  }

  /**
    * 本地设备改变回调,新增，删除，启用设备
    * @param deviceId: 设备 ID
    * @param type: 设备类型
    * @param state: 事件类型
  */
  onDeviceChange() {
    this.roomClient.on('xxxxx',()=>{
      this._beforeSafeEmit('onDeviceChange');
    })
  }

  /**
    * 跟服务器的连接断开
    * @param reason: 连接丢失的原因，如果没有则是NULL。
  */
  onConnectionLost() {
    this.roomClient.on('xxxxx',()=>{
      this._beforeSafeEmit('onConnectionLost');
    })
  }

  /**
   * 尝试重新连接到服务器
   * @param after: 多少秒后开始重连, 初始10秒，意思是10秒后偿试重连操作
   */
  onTryToReconnect() {
    this.roomClient.on('xxxxx',()=>{
      this._beforeSafeEmit('onTryToReconnect');
    })
  }

  /**
   * 跟服务器的连接恢复
   *
   */
  onConnectionRecovered() {
    this.roomClient.on('xxxxx',()=>{
      this._beforeSafeEmit('onConnectionRecovered');
    })
  }

  /**
   * 当屏幕分享开始时，SDK 会通过此回调通知
   *
   */
  onScreenCaptureStarted() {
    this.roomClient.on('screenCaptureStarted',()=>{
      this._beforeSafeEmit('onScreenCaptureStarted');
    })
  }

  /**
    * 当屏幕分享暂停时，SDK 会通过此回调通知
    *  @param reason: 停止原因
    * - SCREENCAPTUREPAUSED_NORMAL：表示用户主动暂停；
    * - SCREENCAPTUREPAUSED_TEMP：表示设置屏幕分享参数导致的暂停；
    * - SCREENCAPTUREPAUSED_WINDOW_MINIMIZED：表示屏幕分享窗口被最小化导致的暂停；
    * - SCREENCAPTUREPAUSED_WINDOW_HIDED：表示屏幕分享窗口被隐藏导致的暂停
   */
  onScreenCapturePaused() {
    this.roomClient.on('screenCaptureStoped',()=>{
      this._beforeSafeEmit('onScreenCapturePaused');
    })
  }

  /**
   * 当屏幕分享恢复时，SDK 会通过此回调通知
   *
   */
  onScreenCaptureResumed() {
    this.roomClient.on('xxxxx',()=>{
      this._beforeSafeEmit('onScreenCaptureResumed');
    })
  }

  /**
    * 当屏幕分享停止时，SDK 会通过此回调通知
    * @param reason 停止原因，
    * SCREENCAPTURESTOP_NORMAL：用户自己主动关闭，
    * SCREENCAPTURESTOP_WINDOW_CLOSED：表示屏幕分享窗口被关闭
   */
  onScreenCaptureStoped() {
    this.roomClient.on('xxxxx',()=>{
      this._beforeSafeEmit('onScreenCaptureStoped');
    })
  }

  /**
    * 房间内的某人调用了changeDisplayName修改了名称
    * @param userId: 改名人的userId
    * @param oldDisplayName: 修改前的旧名称
    * @param newDisplayName: 修改后的新名称
   */
  onDisplayNameChanged() {
    this.roomClient.on('displayNameChanged',(currentChangedName)=>{
      this._beforeSafeEmit('onDisplayNameChanged', currentChangedName);
    })
  }

  /**
    * 本地发出的会控请求的结果回调
    * @param type: 对应的会控类型
    * @param code: 返回码
    * @param msg： 对应的消息，如果code是错误，这里是错误码
   */
  onMeetingControlResult() {
    this.roomClient.on('xxxxx',()=>{
      this._beforeSafeEmit('onMeetingControlResult');
    })
  }

  /**
    * 通知上层APP，当前正在说话的人，并附有当前说话者的音量信息。
    * @note: 如果你想显示用户的音量信息，应该响应onUserVoiceVolume。此回调主要用于获知房间内的有哪些人在说话
    * 应用场景例如：千人会议中知道哪些人正在说话，从而决定拉取哪些人的音频流。
    * @param userVolumes: 说话人的信息, 其中音量信息在此回调中可能并不准备，也有可能没有，如果有则是以0-100为区间的数值.
    * @param userVolumesCount: userVolumes数组有多少个元素
   */
  onActiveSpeakers() {
    this.roomClient.on('xxxxx',()=>{
      this._beforeSafeEmit('onActiveSpeakers');
    })
  }

  /**
    * 自定义视频渲染回调
    * 可以通过 setLocalVideoRenderCallback 和 setRemoteVideoRenderCallback 接口设置自定义渲染回调
    * @param userId     用户标识, 传入 "" 则表示本地用户
    * @param streamType 流类型：即摄像头还是屏幕分享
    * @param frame      视频帧数据
   */
  onRenderVideoFrame() {
    this.roomClient.on('xxxxx',()=>{
      this._beforeSafeEmit('onRenderVideoFrame');
    })
  }

  // 用于收集 事件集合，用于在 destroy 移除
  _beforeSafeEmit(event, ...arg) {
    this.events.push(event);

    this.app.safeEmit(event, ...arg)
  }
  /** events -- end */

  reset() {
    // 清空 RTCSDK 数据
    this.roomClient.exitRoom(true);
  }
  /**
   * 销毁
   */
  destroy() {
    console.error('web-sdk RTC destroy');
    this.events.map(item => {
      this.app.removeAllListeners(item)
    })

  }
}
