<template>
  <div>
    <svg
      :class="['icon' + fontSize, target]"
      aria-hidden="true"
      @mouseover="mouseover"
      @mouseout="mouseout"
      @mousedown="mousedown"
      @mouseup="mouseup"
      :data-item="dataAttr"
      @click="click"
    >
      <use :data-item="dataAttr" :xlink:href="setIcon" />
    </svg>
  </div>
</template>

<script>
import "@/assets/css/iconFont100/iconfont.js";
export default {
  props: {
    target: { default: "" }, // 图标样式名称
    fontSize: { default: 24 }, //图标大小
    iconName: { default: "" }, // icon名称
    dataAttr:{default: "" }, // 自定义data属性
  },
  computed: {
    // 设置图标名称
    setIcon() {
      return "#" + this.iconName;
    },
  },
  methods: {
    // 鼠标经过
    mouseover() {
      this.$emit("mouseover");
    },
    // 鼠标离开
    mouseout() {
      this.$emit("mouseout");
    },
    mousedown() {
      this.$emit("mousedown");
    },
    // 鼠标抬起
    mouseup() {
      this.$emit("mouseup");
    },
    // 鼠标点击
    click(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.icon16 {
  width: 16px;
  height: 16px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.icon20 {
  width: 20px;
  height: 20px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.icon24 {
  width: 24px;
  height: 24px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.icon32 {
  width: 32px;
  height: 32px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.icon44 {
  width: 44px;
  height: 44px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.icon48 {
  width: 48px;
  height: 36px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.icon60 {
  width: 60px;
  height: 60px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.icon64 {
  width: 64px;
  height: 64px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.icon82 {
  width: 82px;
  height: 36px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
