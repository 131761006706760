const conversationModules = {
  state: {
    currentMessageList: [],

    unreadMessageCount: 0,

    chatInfo: {
      roomID: '',
      userId: '',
      avatar: 'https://yibaiserver-1252497236.file.myqcloud.com/yibai/default_user_avatar.png'
    }
  },
  mutations: {
    setChatInfo (state, data) {
      state.chatInfo.roomID = data.roomID
      state.chatInfo.userId = data.userID
    },

    updateUnReadMessageCount(state) {
      state.unreadMessageCount++
    },
    resetUnReadMessageCount(state) {
      state.unreadMessageCount=0
    },

    /**
     * 将消息插入当前会话列表
     * 调用时机：收/发消息事件触发时
     * @param {Object} state
     * @param {Message[]|Message} data
     * @returns
     */
    pushCurrentMessageList (state, data) {
      if (Array.isArray(data)) {
        state.currentMessageList = [...state.currentMessageList, ...data]
      } else {
        let isRepeat = false

        // 首先判断是不是自己发送消息的
        if (state.chatInfo.userId === data.uid) {
          // 再判断消息是否重复
          for (let item of state.currentMessageList) {
            if (item.uid === data.uid && item.seq === data.seq && item.timestamp === data.timestamp) {
              item.status = data.status
              item.avatar = data.avatar
              item.id = data.id
              isRepeat = true
              break
            }
          }
        }

        if (data.failId) { // 代表失败的消息重发成功了
          state.currentMessageList = state.currentMessageList.filter(i => {
            return !(i.failId && i.failId === data.failId)
          })
        }

        if (!isRepeat) {
          console.log('消息没有重复---');
          // state.currentMessageList = [...state.currentMessageList, data]

          state.currentMessageList = mergeMessageList(state.currentMessageList, state.currentMessageList.length - 1, data)
        } else {
          console.log('消息重复了---')
        }
      }
    },

    resetMessageList(state) {
      state.currentMessageList = [] 
      state.unreadMessageCount = 0
    },

    reset (state) {
      Object.assign(state, {
        currentMessageList: []
      })
    }
  }
}

function mergeMessageList(targetArray, lastIndex, newMessage) {
  if (targetArray.length === 0) {
    targetArray.push(newMessage)
    return targetArray
  }

  // 暂时只考虑 source是对象的情况
  if (Number(newMessage.timestamp) >= Number(targetArray[lastIndex].timestamp)) {
    // 最后一个
    if (lastIndex === targetArray.length  - 1) {
      targetArray.push(newMessage)
    } else {
      targetArray.splice(lastIndex + 1, 0, newMessage)
    }
    return targetArray
  } else {
    if (lastIndex === 0) {
      targetArray.splice(0, 0, newMessage)
      return targetArray
    } else {
      return mergeMessageList(targetArray, lastIndex - 1, newMessage)
    }
  }
}

export default conversationModules
