<!--
* 日期工具组件
* version: 1.0
* author:  SunAo
* time: 2018-07-23
* compatible: IE10+、firefox、Chrome
* 说明：对Vue方法扩展的一些针对日期常用小方法
*
* 方法:
* 1. $parseDate: 将字符串日期值转换成date类型日期值（兼容方法）
* 2. $compareDate: 比较日期大小方法，> 0 表示: 结束时间 > 开始时间
* 3. $getDay: 获取每月有多少天
* 4. $getWeek: 获取星期几
* 5. $diffDate: 计算两日期相差的天数
* 6. $addDate: 增减日期
* 7. $getDate: 获取当前日期方法
* 8. $getTime: 获取当前时间方法
* 9. $getDateTime: 获取当前日期+时间
* 10. $dateFormat: 将date日期类型转换成字符串日期类型
* 11. $getLongTime: 根据日期字符串转换成long值
* 12. $getDateByLong: 根据long值转换成日期字符串
* 13. $diffDateTime: 计算两带时间日期相差的分钟
* 14. $getYearWeek: 某日期是第几周
* 15. $getWeekDate: 根据年、月、周、星期几获取具体日期
* 16. $formatSeconds: 将秒格式化成 1小时10分13秒 hh-mm-ss
* 17. $getWeekWord: 获取星期几(中文)
* 18. $getAmPmWord: 获取上下午(中文)
-->
<template>
  <div class="none"></div>
</template>

<script>
import Vue from "vue";
export default {
  created() {
    let that = this;
    let _parse = Date.parse;
    let $parseInt = that.$parseInt;

    /*
     * 1. 将字符串日期值转换成date类型日期值
     * date: 字符串日期(2011-01-12 或 2011-01-12 00:00:00)
     */
    Vue.prototype.$parseDate = function (date) {
      var d, a, a1, a2;
      if (date.length > 10) {
        a = date.split(" ");
        a1 = a[0].split("-");
        a2 = a[1].split(":");
        d = new Date(a1[0], a1[1] - 1, a1[2], a2[0], a2[1], a2[2]);
      } else {
        a = date.split("-");
        let month = $parseInt(a[1]) - 1;
        let day = $parseInt(a[2]);
        d = new Date(a[0], month, day);
      }
      return d;
    };

    /*
     * 2. 比较日期大小方法
     * start: 开始日期
     * end: 结束日期
     */
    Vue.prototype.$compareDate = function (start, end) {
      let data1 = createDate(start);
      let data2 = createDate(end);
      return _parse(data2) - _parse(data1);
    };

    /*
     * 3. 获取每月有多少天
     * year: 年, 可以是number和String类型
     * month: 月, 可以是number和String类型
     * 示例: $.getDay(2014, 10)
     */
    Vue.prototype.$getDay = function (year, month) {
      return (
        (new Date(year, month, 1) - new Date(year, month - 1, 1)) /
        (1000 * 60 * 60 * 24)
      );
    };

    /*
     * 4. 获取星期几
     * dayValue: 字符串日期(2010-1-1)
     * 说明，返回number类型0-6, 0(日),1(一),2(二),3(三),4(四),5(五),6(六)
     * 示例: $.getWeek(_date1)
     */
    Vue.prototype.$getWeek = function (date) {
      return createDate(_parse(date.replace(/-/g, "/"))).getDay();
    };

    /*
     * 5. 计算两日期相差的天数
     * d1: 日期一
     * d2: 日期二
     * 示例: alert($.diffDate(_date1,_date3));
     */
    Vue.prototype.$diffDate = function (d1, d2) {
      return $parseInt(
        (_parse(d2.replace(/-/g, "/")) - _parse(d1.replace(/-/g, "/"))) /
          (1000 * 24 * 3600)
      );
    };

    /*
     * 6. 增减日期
     * type:增加类型
     * num:增加数量
     * date:字符串日期(2010-01-01)
     * str: 日期格式
     * 示例: $.addDate('d', -3, _date1);
     */
    Vue.prototype.$addDate = function (type, num, date, str) {
      // 临时处理31号到下一个月算成2月的情况
      if (date.indexOf("31") > -1 && num === 1) {
        let dataArray = date.split(" ");
        let _dataArray = dataArray[0].split("-");
        let year = _dataArray[0];
        let month = _dataArray[1];
        let time = "";
        month = $parseInt(month) + 1;
        if (month < 10) {
          month = "0" + month;
        }
        if (dataArray.length === 2) {
          time = dataArray[1];
        }
        return year + "-" + month + "-01 " + time;
      }
      date = createDate(date);
      return that.$dateFormat(innerSetDate(type, date, num), str);
    };

    // 7. 获取当前日期方法，格式: 2014-08-28
    Vue.prototype.$getDate = function () {
      let date = createDate();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      return year + "-" + month + "-" + day;
    };

    /*
     * 8. 获取当前时间方法
     * isSecond: 是否显示秒
     */
    Vue.prototype.$getTime = function (isSecond) {
      let date = createDate();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }
      if (second < 10) {
        second = "0" + second;
      }
      let value = hour + ":" + minute;
      if (isSecond) {
        value = value + ":" + second;
      }
      return value;
    };

    /*
     * 9. 获取当前日期+时间
     * isSecond: 是否显示秒
     */
    Vue.prototype.$getDateTime = function (isSecond) {
      return that.$getDate() + " " + that.$getTime(isSecond);
    };

    /*
     * 10. 将date日期类型转换成字符串日期类型
     * date: 日期
     * str: 日期格式, 默认: yyyy-MM-dd
     * 示例: $.dateFormat(date, 'yyyy-MM-dd hh:mm:ss');
     * 说明: 这里的月份获取自动加了一个月，所以，与js内置的记法不同。
     */
    Vue.prototype.$dateFormat = function (date, str) {
      // let Week = ["日", "一", "二", "三", "四", "五", "六"];
      let Week = [this.$t('seeting.Sun'), this.$t('seeting.Mon'), this.$t('seeting.Tues'),this.$t('seeting.Wed'),this.$t('seeting.Thur'),this.$t('seeting.Fri'),this.$t('seeting.Sat')];

      let t = date;

      if (typeof t === "string") {
        t = this.$parseDate(t);
      }
      // 默认格式化值
      if (str === undefined) {
        str = "yyyy-MM-dd";
      }
      str = str.replace(/yyyy|YYYY/, t.getFullYear());
      str = str.replace(
        /yy|YY/,
        t.getYear() % 100 > 9
          ? (t.getYear() % 100).toString()
          : "0" + (t.getYear() % 100)
      );
      str = str.replace(
        /MM/,
        t.getMonth() + 1 > 9
          ? (t.getMonth() + 1).toString()
          : "0" + (t.getMonth() + 1)
      );
      str = str.replace(/M/g, t.getMonth() + 1);
      str = str.replace(/w|W/g, Week[t.getDay()]);
      str = str.replace(
        /dd|DD/,
        t.getDate() > 9 ? t.getDate().toString() : "0" + t.getDate()
      );
      str = str.replace(/d|D/g, t.getDate());
      str = str.replace(
        /hh|HH/,
        t.getHours() > 9 ? t.getHours().toString() : "0" + t.getHours()
      );
      str = str.replace(/h|H/g, t.getHours());
      str = str.replace(
        /mm/,
        t.getMinutes() > 9 ? t.getMinutes().toString() : "0" + t.getMinutes()
      );
      str = str.replace(/m/g, t.getMinutes());
      str = str.replace(
        /ss|SS/,
        t.getSeconds() > 9 ? t.getSeconds().toString() : "0" + t.getSeconds()
      );
      str = str.replace(/s|S/g, t.getSeconds());
      return str;
    };

    /*
     * 11. 根据日期字符串转换成long值
     * dateTime: 字符串日期
     */
    Vue.prototype.$getLongTime = function (dateTime) {
      var _dateTime = dateTime.replace(/-/g, "/"); // 解决IE下无法转换问题
      return _parse(_dateTime);
    };

    /**
     * 12. 根据long值转换成日期字符串
     * longDate: long值
     * pattern: 格式化模板（yyyy-MM-dd hh:mm:ss）
     */
    Vue.prototype.$getDateByLong = function (longDate, pattern) {
      var date = createDate(longDate);
      return that.$dateFormat(date, pattern);
    };

    /*
     * 13. 计算两日期相差的分钟
     * d1: 日期一
     * d2: 日期二
     * 示例: alert($.diffDate(_date1,_date3));
     */
    Vue.prototype.$diffDateTime = function (d1, d2) {
      // 判断开始时间是否大于结束日期
      if (d1 > d2) {
        return 0;
      }

      // 截取字符串，得到日期部分"2009-12-02",用split把字符串分隔成数组
      let begin1 = d1.substr(0, 10).split("-");
      let end1 = d2.substr(0, 10).split("-");

      // 将拆分的数组重新组合，并实例成化新的日期对象
      let date1 = new Date(begin1[1] + "/" + begin1[2] + "/" + begin1[0]);
      let date2 = new Date(end1[1] + "/" + end1[2] + "/" + end1[0]);

      // 得到两个日期之间的差值m，以分钟为单位
      // Math.abs(date2-date1)计算出以毫秒为单位的差值
      // Math.abs(date2-date1)/1000得到以秒为单位的差值
      // Math.abs(date2-date1)/1000/60得到以分钟为单位的差值
      let m = $parseInt(Math.abs(date2 - date1) / 1000 / 60);

      // 小时数和分钟数相加得到总的分钟数
      // time1.substr(11,2)截取字符串得到时间的小时数
      // parseInt(time1.substr(11,2))*60把小时数转化成为分钟
      let min1 = $parseInt(d1.substr(11, 2)) * 60 + $parseInt(d1.substr(14, 2));
      let min2 = $parseInt(d2.substr(11, 2)) * 60 + $parseInt(d2.substr(14, 2));

      // 两个分钟数相减得到时间部分的差值，以分钟为单位
      let n = min2 - min1;

      // 将日期和时间两个部分计算出来的差值相加，即得到两个时间相减后的分钟数
      let minutes = m + n;
      return minutes;
    };

    /*
     * 14.某日期是第几周
     * y: 年
     * m: 月
     * d: 日
     * 示例: this.$getYearWeek(2018, 8, 1); 获取2018年8月周一是第几周,2表示周一
     */
    Vue.prototype.$getYearWeek = function (y, m, d) {
      let targetDay = new Date(y, m - 1, d);
      let year = targetDay.getFullYear();
      let days = targetDay.getDate();
      // 那一天是那一年中的第多少天
      for (var i = 1; i < m; i++) {
        days += this.$getDay(year, i);
      }
      // 那一年第一天是星期几
      var yearFirstDay = new Date(year, 0, 1).getDay();
      // 计算是第几周
      days -= 1;
      days += yearFirstDay;
      var week = Math.ceil(days / 7);
      return week;
    };

    /*
     * 15.根据年、月、周、星期几获取具体日期
     * year: 年
     * month: 月
     * weeks: 周(1-4的数)
     * weekDay: 星期几(1表示周一)
     * 示例: this.$getWeekDate(2018, 8, 4, 1); 获取2018年8月第四周的周一的日期
     */
    Vue.prototype.$getWeekDate = function (year, month, weeks, weekDay) {
      // 用指定的年构造一个日期对象，并将日期设置成这个年的1月1日
      // 因为计算机中的月份是从0开始的,所以有如下的构造方法
      var date = new Date(year, "0", "1");

      // 计算周
      let innerWeeks = this.$getYearWeek(year, month, 1) + weeks;

      // 取得这个日期对象 date 的长整形时间 time
      var time = date.getTime();

      // 将这个长整形时间加上第N周的时间偏移
      // 因为第一周就是当前周,所以有:weeks-1,以此类推
      // 7*24*3600000 是一星期的时间毫秒数,(JS中的日期精确到毫秒)
      time += (innerWeeks - 1) * 7 * 24 * 3600000;

      // 为日期对象 date 重新设置成时间 time
      date.setTime(time);
      let day = getNextDate(date, weekDay);
      day = this.$dateFormat(day);
      return day;
    };

    Vue.prototype.$getAllWeekDate = function (year, weeks, weekDay) {
      // 用指定的年构造一个日期对象，并将日期设置成这个年的1月1日
      // 因为计算机中的月份是从0开始的,所以有如下的构造方法
      var date = new Date(year, "0", "1");
      // 取得这个日期对象 date 的长整形时间 time
      var time = date.getTime();
      // 将这个长整形时间加上第N周的时间偏移
      // 因为第一周就是当前周,所以有:weeks-1,以此类推
      // 7*24*3600000 是一星期的时间毫秒数,(JS中的日期精确到毫秒)
      time += (weeks - 1) * 7 * 24 * 3600000;
      // 为日期对象 date 重新设置成时间 time
      date.setTime(time);
      let day = getNextDate(date, weekDay);
      day = this.$dateFormat(day);
      return day;
    };

    // 16.将秒格式化成 1小时10分13秒 hh-mm-ss, seconds: 秒钟
    Vue.prototype.$formatSeconds = function (seconds) {
      var theTime = parseInt(seconds); // 秒
      var middle = 0; // 分
      var hour = 0; // 小时

      if (theTime > 60) {
        middle = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (middle > 60) {
          hour = parseInt(middle / 60);
          middle = parseInt(middle % 60);
        }
      }
      var result = "" + parseInt(theTime) + this.$t('login.second');
      if (middle > 0) {
        result = "" + parseInt(middle) + this.$t('login.mintue')  + result;
      }
      if (hour > 0) {
        result = "" + parseInt(hour) + this.$t('login.hour') + result;
      }
      return result;
    };

    /*
     * 17. 获取星期几(中文)
     * dayValue: 字符串日期(2010-01-01)
     * 说明，返回number类型0-6, 0(日),1(一),2(二),3(三),4(四),5(五),6(六)
     * 示例: $.getWeek(_date1)
     */
    Vue.prototype.$getWeekWord = function (date) {
      // let Week = ["日", "一", "二", "三", "四", "五", "六"];
      let Week = [this.$t('seeting.Sun'), this.$t('seeting.Mon'), this.$t('seeting.Tues'),this.$t('seeting.Wed'),this.$t('seeting.Thur'),this.$t('seeting.Fri'),this.$t('seeting.Sat')];
      let index = createDate(_parse(date.replace(/-/g, "/"))).getDay();
      return Week[index];
    };

    /*
     * 18. 获取上下午(中文)
     * dayValue: 字符串日期(2010-01-01)
     * 说明，返回number类型0-6, 0(日),1(一),2(二),3(三),4(四),5(五),6(六)
     * 示例: $.getWeek(_date1)
     */
    Vue.prototype.$getAmPmWord = function (date) {
      let _date = this.$parseDate(date);
      let hours = _date.getHours();
      return hours > 12 ? this.$t('seeting.afternoon') : this.$t('seeting.moring');
    };

    // 这个方法将取得 某日期(nowDate) 所在周的星期几(weekDay)的日期
    function getNextDate(nowDate, weekDay) {
      // 0是星期日,1是星期一,...
      weekDay %= 7;
      var day = nowDate.getDay();
      var time = nowDate.getTime();
      var sub = weekDay - day;
      time += sub * 24 * 3600000;
      nowDate.setTime(time);
      return nowDate;
    }

    // 增加日期内部方法
    function innerSetDate(type, d, n) {
      switch (type) {
        case "s":
          return new Date(_parse(d) + 1000 * n);
        case "n":
          return new Date(_parse(d) + 60000 * n);
        case "h":
          return new Date(_parse(d) + 3600000 * n);
        case "d":
          return new Date(_parse(d) + 86400000 * n);
        case "w":
          return new Date(_parse(d) + 86400000 * 7 * n);
        case "m": {
          return new Date(
            d.getFullYear(),
            d.getMonth() + n,
            d.getDate(),
            d.getHours(),
            d.getMinutes(),
            d.getSeconds()
          );
        }
        case "y":
          return new Date(
            d.getFullYear() + n,
            d.getMonth(),
            d.getDate(),
            d.getHours(),
            d.getMinutes(),
            d.getSeconds()
          );
      }
    }

    // 创建日期对象方法
    function createDate(date) {
      // 自动补秒
      if (date !== undefined && date.length === 16) {
        date = date + ":00";
      }
      var d = new Date(date);
      if (isNaN(d)) {
        try {
          d = that.$parseDate(date);
        } catch (e) {
          d = new Date();
        }
      }
      return d;
    }

    // console.log(this.$getYearWeek(2018, 8, 1))
    // console.log(this.$getWeekDate(2018, 0, 2, 1))
    // console.log(that.$parseDate('2018-07-07'))
    // console.log(that.$parseDate('2018-07-07 22:22:22'))
    // console.log('---------------------------------------------------')
    // console.log(that.$compareDate('2018-07-07', '2018-07-08'))
    // console.log(that.$compareDate('2018-07-07', '2018-07-07'))
    // console.log(that.$compareDate('2018-07-08 22:22:22', '2018-07-07 12:22:22'))
    // console.log('---------------------------------------------------')
    // console.log(that.$getDay('2018', '07')) // 整数也行
    // console.log(that.$getWeek('2018-07-24'))
    // console.log('---------------------------------------------------')
    // console.log(that.$diffDate('2018-07-07', '2018-08-08'))
    // console.log(that.$addDate('d', -1, '2018-07-07'))
    // console.log('---------------------------------------------------')
    // console.log(that.$getDate())
    // console.log(that.$getTime(true))
    // console.log(that.$getDateTime(true))
    // console.log('---------------------------------------------------')
    // console.log(that.$getLongTime('2018-07-07 12:12:12'))
    // console.log(that.$getDateByLong(that.$getLongTime('2018-07-07 12:12:12'), 'yyyy-MM-dd hh:mm:ss'))
  },
};
</script>

<style scoped>
</style>
