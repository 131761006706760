import global from '../util/global'
import EventEmitter from 'events'
import utils from "../util/utils";
import IM_EVENT from "./im-events";
class SocketPlugin {
    constructor(params) {
        this.websocket = null
        this.isSelfLogOut = false // 是否是用户自己主动退出房间
        this.reconnentCount = 0 // 当前重连次数
        this.reconnectMaxCount = 4 // 最大的重连次数
        this.socketIsOpen = false  // socket是否打开
        this.params = params
        this._emitter = new EventEmitter()
        this.setIntervalWesocketPush = null
    }

    on(event, listener) {
        this._emitter.addListener(event, listener)
    }

    once(event, listener) {
        this._emitter.once(event, listener)
    }

    off(event, listener){
        this._emitter.removeListener(event, listener)
    }

    connect(){
        this.websocket = new WebSocket(this.params.url)
        this.initSocket()
    }

    initSocket(){
        this.websocket.binaryType = 'arraybuffer'
        this.websocket.onopen = this.onopenWS.bind(this)
        this.websocket.onmessage = this.onmessageWS.bind(this)
        this.websocket.onerror = this.onerrorWS.bind(this)
        this.websocket.onclose = this.oncloseWS.bind(this)
    }

    onopenWS(){
      console.log('[ybmeet IM] websocket | onopenWS() |')
        this._emitter.emit(IM_EVENT.USER_JOIN)
        this.sendPing()

        this.socketIsOpen = true
        // 清空重连的次数
        this.reconnentCount = 0
    }

    onerrorWS(err){
      console.log('[ybmeet IM] websocket error | onerrorWS() |', err)
    }

    oncloseWS(err){ // websocket断开连接，服务端主动关闭，端上因为断网或者其他原因关闭
      console.log('[ybmeet IM] websocket closed | oncloseWS() |', err)

        // socket断开连接
        this.socketIsOpen = false
        if (this.websocket){
            clearInterval(this.setIntervalWesocketPush)
            this.websocket = null
            if (this.isSelfLogOut){
                this._emitter.emit(IM_EVENT.USER_LEAVE)
            }
        }

        /**
         * code:
         * 1000 服务端下发关闭
         * 1005 客户端主动关闭
         */
         if (err.code === 1000) { // 服务端主动关闭，这时候不用触发重连
          // console.error('socke服务端下发关闭，不需要重连-------')
          console.log('[ybmeet IM] websocket | oncloseWS() | kicked out')

          this._emitter.emit(IM_EVENT.REMOVE_SELF)
      } else if (err.code === 1005) {
          console.log('[ybmeet IM] websocket | oncloseWS() | login out')
      } else { // 其他情况下关闭了，需要重连
          // console.error('socket异常关闭，需要进行重连----')
          // 触发重连机制
          if (this.reconnentCount === 0) {
              console.log('[ybmeet IM] websocket | oncloseWS() | try reconnect. counts :', this.reconnentCount)
              // console.log(`第${this.reconnentCount}次重连`)
              this.socketReconnect()
          }
      }
    }

    /**
     * 用户自己退出房间,断开socket连接，然后清空token
     */
    wsOut() {
        this.isSelfLogOut = true
        if (this.websocket){
            this.websocket.close()
        }
    }

    socketReconnect() {
        if (this.socketIsOpen) {
            return false
        }
        this.reconnentCount += 1
        // 判断是否到了最大重连次数
        if (this.reconnentCount >= this.reconnectMaxCount) {
            console.log('重连次数超限')
            this._emitter.emit(IM_EVENT.SOCKET_CONNECT_FAIL)

            return false
        }
        // 初始化
        this.connect()

        let delayTime = 0
        if (this.reconnentCount === 1) {
            delayTime = 5000
        } else if (this.reconnentCount === 2) {
            delayTime = 10000
        } else {
            delayTime = 15000
        }
        // delayTime 尝试一次，检查是否连接成功，直到超过最大重连次数
        let timer = setTimeout(() => {
            this.socketReconnect()
            clearTimeout(timer)
        }, delayTime)
    }

    onmessageWS(evt){
        const data = evt.data
        const dataView = new DataView(data, 0)
        let packetLen = dataView.getInt32(global.packetOffset)
        let headerLen = 0
        let msgBody
        for (let offset = 0; offset < data.byteLength; offset += packetLen) {
            packetLen = dataView.getInt32(offset)
            headerLen = dataView.getInt16(offset + global.headerOffset)
            let op = dataView.getInt32(offset + global.opOffset)
            // console.log('op ' + op)

            if (op === 4) { // 如果消息类型是心跳就返回
                continue
            }
            if (op === 7) {
                this.wsOut()
                // 用户被踢出 断开socket连接
                this._emitter.emit(IM_EVENT.KICKED_OUT_RECEIVED)
                return
            }
            msgBody = data.slice(offset + headerLen, offset + packetLen)
            const response = global.messageRes.decode(new Uint8Array(msgBody))
            if (JSON.stringify(response) === '{}') {
                continue
            }
            console.log('onmessageWS',response)
            const type = response['content']['type']
            //let content = utils.unit8ToString(response['content']['payload'])
            // console.log('content ' + content)
            // type 0:通知， 1：文本， 7：提示
            switch (type) {
                case 0:
                case 7: {
                    //用户加入或离开房间文本提示
                    this._emitter.emit(IM_EVENT.USER_TIPS, {
                        text: utils.unit8ToString(response['content']['payload']),
                        id: response['metaData']['id'],
                        timestamp: response['metaData']['timestamp'],
                    })
                    break
                }
                case 1: {
                    //收到用户发送信息
                    if (response['metaData']['from']['admin']){
                        //控制台管理员群发消息
                        this._emitter.emit(IM_EVENT.MANAGER_GROUP_SEND_MESSAGE_RECEIVED, {
                            text: utils.unit8ToString(response['content']['payload']),
                            avatar: response['metaData']['from']['avatar'],
                            id: response['metaData']['id'],
                            timestamp: response['metaData']['from']['timestamp'], // TODO: 待修改成metaData.timestamp
                            nickname: response['metaData']['from']['nickname'],
                            admin: response['metaData']['from']['admin']
                        })
                    }else{
                        //广播用户发送消息
                        this._emitter.emit(IM_EVENT.TEXT_MESSAGE_RECEIVED, {
                            text: utils.unit8ToString(response['content']['payload']),
                            avatar: response['metaData']['from']['avatar'],
                            id: response['metaData']['id'],
                            timestamp: response['metaData']['from']['timestamp'],
                            nickname: response['metaData']['from']['nickname'],
                            uid: response['metaData']['from']['uid'],
                            seq: response['metaData']['from']['seq'],
                            admin: response['metaData']['from']['admin'],
                            specified: response['metaData']['to']['specified'], //@用户
                            type: response['metaData']['to']['type'] //type是0是单聊，type是2是群发消息
                        })
                    }
                    break
                }
                case 2: {
                    //接收到的是图片返回结果
                    this._emitter.emit(IM_EVENT.IMG_MESSAGE_RECEIVED, {
                        text: utils.unit8ToString(response['content']['payload']),
                        avatar: response['metaData']['from']['avatar'],
                        id: response['metaData']['id'],
                        timestamp: response['metaData']['from']['timestamp'],
                        nickname: response['metaData']['from']['nickname'],
                        uid: response['metaData']['from']['uid'],
                        seq: response['metaData']['from']['seq'],
                        admin: response['metaData']['from']['admin'],
                        specified: response['metaData']['to']['specified'], //@用户
                        type: response['metaData']['to']['type'] //type是0是单聊，type是2是群发消息
                    })
                    break;
                }
                default: {
                    console.log('该类型暂未解析')
                }
            }
        }
    }

    sendPing(time = 30000){
      this.setIntervalWesocketPush = setTimeout(() => {
            /**
            if (!socketIsOpen) {
                return false
            }*/
            // console.log('心跳')
            let headerBuf = new ArrayBuffer(global.rawHeaderLen)
            let headerView = new DataView(headerBuf, 0)
            headerView.setInt32(global.packetOffset, global.rawHeaderLen)
            headerView.setInt16(global.headerOffset, global.rawHeaderLen)
            headerView.setInt16(global.verOffset, 1)
            headerView.setInt32(global.opOffset, global.opHeartbeat)
            headerView.setInt32(global.seqOffset, 1)
            this.websocket.send(headerBuf)
            // clearTimeout(this.setIntervalWesocketPush)
            // 重新执行
            this.sendPing()
        }, time)
    }
}

export default SocketPlugin
