import Base from "../BasePlugin";

import { SDK_ERROR } from '../../constant'
import {
  createConference,
  updateConference,
  fetchConference,
  delConference,
  delMeetRoom,
  fetchOrderList,
  fetchTodayList,
  fetchHistoryDetail,
  optConferenceAgenda,
  fetchAgendaInfo,
  fetchConferenceSnowflake,
  fetchUserInfo,
  joinConference,
  endConference
 } from '../../api/meet'
import { ResultError } from '../../utils/entity'
import { meetAgendasUrl } from "../../config/index"
import { getMeetingRole, getAcceptLanguage } from "../../utils/auth"

import queryString from 'query-string'

class MeetingManagement extends Base {
    constructor(app, config) {
        super(app);
    }

    /********************************** 会议管理服务 ************************************/
   /**
   * 1.创建会议
   */
   createMeeting(entity) {
    return new Promise(async (resolve, reject) => {
      if (!entity) {
        const error = new ResultError(
          'PARAMETERS', 
           SDK_ERROR.PARAMETERS, 
          'createMeeting: parameter error')
        reject(error)
        return
      } 
      
      try {
        const result = await createConference(entity)
        if (result) 
           resolve(result)
      } catch (error) {
        reject(error)
      }

    })
  }

  /**
   * 2.会议编辑
   * @param {*} param 
   */
  modifyMeeting(entity) {
    return new Promise(async (resolve, reject) => {
      if (!entity || !entity.title || !entity.id) {
        const error = new ResultError(
          'PARAMETERS', 
           SDK_ERROR.PARAMETERS, 
          'modifyMeeting: parameter error')
        reject(error)
        return
      }

      try {
        const result = await updateConference(entity)
        if (result) 
           resolve(result)
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * 3.获取会议信息
   * @param {} meetingId 
   */
  getMeetingInfo(meetingId) {
    return new Promise(async (resolve, reject) => {
      if (!meetingId) {
        const error = new ResultError(
          'PARAMETERS', 
           SDK_ERROR.PARAMETERS, 
          'getMeetingInfo: parameter error')
        reject(error)
        return
      }

      try {
        const result = await fetchConference({
          id: meetingId
        })
        if (result) 
           resolve(result)
      } catch (error) {
        reject(error)
      }

    })
  }


  /**
   * 4.删除预约会议
   * @param {*} meetingId 
   */
  deleteMeeting(meetingId) {
    return new Promise(async (resolve, reject) => {
      if (!meetingId) {
        const error = new ResultError(
          'PARAMETERS', 
           SDK_ERROR.PARAMETERS, 
          'deleteMeeting: parameter error')
        reject(error)
        return
      }

      try {
        const result = await delConference({
          id: meetingId
        })
        if (result) 
           resolve(result)
      } catch (error) {
        reject(error)
      }

    })
  }


  /**
   * 5.删除历史会议
   * @param {*} rtcRoomId 
   */
  deleteHistoryMeeting(rtcRoomId) {
    return new Promise(async (resolve, reject) => {
      if (!rtcRoomId) {
        const error = new ResultError(
          'PARAMETERS', 
           SDK_ERROR.PARAMETERS, 
          'deleteHistoryMeeting: parameter error')
        reject(error)
        return
      }

      try {
        const result = await delMeetRoom({
          id: rtcRoomId
        })
        if (result) 
           resolve(result)
      } catch (error) {
        reject(error)
      }

    })
  }


  /**
   * 6.获取预约会议列表
   */
  getScheduleMeetingList({ pageNum, pageSize, param }) {
    return new Promise(async (resolve, reject) => {
      if (!pageNum || !pageSize || !param) {
        const error = new ResultError(
          'PARAMETERS', 
           SDK_ERROR.PARAMETERS, 
          'getScheduleMeetingList: parameter error')
        reject(error)
        return
      }

      try {
        const result = await fetchOrderList({ pageNum, pageSize, param })
        if (result) 
           resolve(result)
      } catch (error) {
        reject(error)
      }

    })
  }

  /**
   * 7.获取历史会议列表
   */
  getHistoryMeetingList({ pageNum, pageSize, param }) {
    return new Promise(async (resolve, reject) => {
      if (!pageNum || !pageSize || !param) {
        const error = new ResultError(
          'PARAMETERS', 
           SDK_ERROR.PARAMETERS, 
          'getHistoryMeetingList: parameter error')
        reject(error)
        return
      }

      try {
        const result = await fetchHistoryDetail({ pageNum, pageSize, param })
        if (result) 
           resolve(result)
      } catch (error) {
        reject(error)
      }

    })
  }

  /**
   * 8.取今日会议列表
   */
  getTodayMeetingList({ pageNum, pageSize, param }) {
    return new Promise(async (resolve, reject) => {
      if (!pageNum || !pageSize || !param) {
        const error = new ResultError(
          'PARAMETERS', 
           SDK_ERROR.PARAMETERS, 
          'getTodayMeetingList: parameter error')
        reject(error)
        return
      }

      try {
        const result = await fetchTodayList({ pageNum, pageSize, param })
        if (result) 
           resolve(result)
      } catch (error) {
        reject(error)
      }

    })
  }

  /**
   * 9.编辑会议议程
   * @param {*} entity 
   */ 
  editMeetingAgenda(entity) {
    return new Promise(async (resolve, reject) => {
      if (!entity || !entity.userId || !entity.id) {
        const error = new ResultError(
          'PARAMETERS', 
           SDK_ERROR.PARAMETERS, 
          'editMeetingAgenda: parameter error')
        reject(error)
        return
      }
  
      if (!entity.hasOwnProperty('forceUpdate')) {
        entity.forceUpdate = 0 // 0:不强制；1：强制
      }
      if (!entity.hasOwnProperty('agendaPermission')) {
        entity.agendaPermission = 0 // 会议议程权限 0：所有人可见 1：仅主持人可见
      }

      try {
        const result = await optConferenceAgenda(entity)
        if (result) 
           resolve(result)
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * 10.新增会议议程
   * @param {*} entity 
   */ 
  createMeetingAgenda(entity) {
    return new Promise(async (resolve, reject) => {
      if (!entity || !entity.userId) { 
        const error = new ResultError(
          'PARAMETERS', 
           SDK_ERROR.PARAMETERS, 
          'createMeetingAgenda: parameter error')
        reject(error)
        return
      }
  
      
      if (!entity.hasOwnProperty('forceUpdate')) {
        entity.forceUpdate = 0 // 0:不强制；1：强制
      }
      if (!entity.hasOwnProperty('agendaPermission')) {
        entity.agendaPermission = 0 // 会议议程权限 0：所有人可见 1：仅主持人可见
      }
   
      try {
        const agendaId = await fetchConferenceSnowflake()
        if (agendaId) {
           entity.id = agendaId
           const result = await optConferenceAgenda(entity)
           if (result) 
              resolve(result)
        } else {
          const error = new ResultError(
            'SDKERR_CREATE_MEETING_AGENDA', 
             SDK_ERROR.SDKERR_CREATE_MEETING_AGENDA, 
            'createMeetingAgenda: create agendaId failed')
          reject(error)
        }
        
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * 11.获取会议议程
   */
  getMeetingAgenda(conferenceId, agendaId) {
    return new Promise(async (resolve, reject) => {
      if (!agendaId) {
        const error = new ResultError(
          'PARAMETERS', 
           SDK_ERROR.PARAMETERS, 
          'getMeetingAgenda: parameter error')
        reject(error)
        return
      }

      try {
        const result = await fetchAgendaInfo({ conferenceId, agendaId })
        if (result) 
           resolve(result)
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * 12.获取议程url
   * @param {*} conferenceId
   */
  getMeetingAgendaURL(conferenceId, roleCode) {
    return new Promise(async (resolve, reject) => {
      const langue = getAcceptLanguage() === 'zh-CN' ? 'zh' : 'en'

      let meetingInfo = null
      let newAgendaId = null
      let userInfo = null
   
      try {
         if (conferenceId) {
            meetingInfo = await fetchConference({
               id: conferenceId
             })
         }
         if(!conferenceId || !meetingInfo?.agendaId) {
            newAgendaId = await fetchConferenceSnowflake()
         }
         userInfo = await fetchUserInfo()
      } catch (error) {
        reject(error)
        return
      }
   
      //缓存获取用户会议角色 （入会时存储）
      // const roleCode = getMeetingRole()
   
      const paramStr = queryString.stringify(
         {
            langue,
            status: roleCode ?? 20,
            meetingId: conferenceId,
            agendaId: newAgendaId ?? meetingInfo?.agendaId,
            userId: userInfo?.userId,
            jurisdiction: meetingInfo?.agendaPermission === 0 ? 0 : 1
         }
      )
   
      const url = meetAgendasUrl + "/h5/agendas?" + paramStr
      resolve(url)
    })
  }


  // -------------------------- 补充 ----------------------
  /**
   * 13.加入会议
   * @param {*} entity 
   */
  joinMeeting(entity) {
    return new Promise(async (resolve, reject) => {
      if (!entity || !entity.conferenceNo || !entity.userName) {
        const error = new ResultError(
          'PARAMETERS', 
           SDK_ERROR.PARAMETERS, 
          'joinMeeting: parameter error')
        reject(error)
        return
      }
      
      try {
        entity.closeConference = true
        const result = await joinConference(entity)
        if (result) 
           resolve(result)
      } catch (error) {
        reject(error)
      }

    })
  }

  /**
   * 14.强制结束会议
   * @param {会议roomId} roomId  
   */
   endMeeting(roomId) {
    return new Promise(async (resolve, reject) => {
      if (!roomId) {
        const error = new ResultError(
          'PARAMETERS', 
           SDK_ERROR.PARAMETERS, 
          'endMeeting: parameter error')
        reject(error)
        return
      }
      
      try {
        const result = await endConference({
          id: roomId
        })
        if (result) 
           resolve(result)
      } catch (error) {
        reject(error)
      }

    })
  }
}

export default MeetingManagement
