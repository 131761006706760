import { loganLog } from "@/utils/log"

// 设置设备缓存值
// const deviceInfoKey = "LOCALDEVICESET";
const deviceInfoKey = "LOCAL_MEETTING_SET"; //和底层对齐


const deviceAuthKey = 'deviceAuth'

/**
 * audioDeviceId: ''
 * audioValue: 50,
 *
 * speakerDeviceId: ''
 * speakerValue: ''
 *
 * videoDeviceId: ''
 * videoQuality: '' //视频画质
 *
 * joinDuration: 1 //是否显示我的参会时长 1|0 默认1
 * joinDurationTag: 0 // 是否首次勾选参会时长
 * shareLimit: 0 //是否设置我的屏幕共享限制 0|1 默认0
 * shareLimitValue: '' //共享限制每秒帧数
 * 
 * hideVideoMember: 0 //隐藏视频参会者 选项 默认不隐藏
 */

export function getLocalDeviceInfo() {
  const data = localStorage.getItem(deviceInfoKey);

  return data ? JSON.parse(data) : {};
}

export function setLocalDeviceInfo(data) {
  const localData = getLocalDeviceInfo();

  console.log(`[utils device.js] setLocalDeviceInfo()｜设置本地设备信息：`, { params: data, localData: localData } );
  localStorage.setItem(
    deviceInfoKey,
    JSON.stringify({
      ...localData,
      ...data,
    })
  );
}

export function removeLocalDeviceInfo() {
  localStorage.removeItem(deviceInfoKey);
}


export function getDeviceAuth () {
    return localStorage.getItem(deviceAuthKey)
}
export function setDeviceAuth (val) {
    localStorage.setItem(deviceAuthKey, val)
}
export function removeDeviceAuth () {
    localStorage.removeItem(deviceAuthKey)
}



export function checkDevicePermission(type="all") {
  return new Promise(async (resolve, reject) => {
    if(type=="audio"){
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        stream.getTracks().forEach((track) => track.stop());
        loganLog("麦克风权限获取成功了");
        resolve();
      } catch (error) {
        loganLog("麦克风权限获取失败" + error);
        reject({type:"audio"});
      }
    }else if(type=="video"){
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        stream.getTracks().forEach((track) => track.stop());
        loganLog("摄像头权限获取成功了");
        resolve();
      } catch (error) {
        loganLog("摄像头权限获取失败" + error);
        reject({type:"audio"});
      }
    }else{
      let _video = true, _audio = true
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        stream.getTracks().forEach((track) => track.stop());
        loganLog("摄像头权限获取成功了");
      } catch (error) {
        _video = false
        loganLog("摄像头权限获取失败" + error);
      }
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        stream.getTracks().forEach((track) => track.stop());
        loganLog("麦克风权限获取成功了");
      } catch (error) {
        _audio = false
        loganLog("麦克风权限获取失败" + error);
      }
      if(_audio && _video){
        resolve();
      }else{
        reject({type: (!_audio && !_video) ? "all" : !_audio ?  "audio" : "video"});
      }
    }
  })
}

