import '@/lib/vue.plugin/Vue.Directive.Install.js'
import VueExtend from '@/lib/vue.plugin/Vue.Extend.vue'
import VueExtendDate from '@/lib/vue.plugin/Vue.Extend.Date.vue'
import VueIcon from '@/lib/vue.plugin/Vue.Svg.Icon.vue'
import VueDragResize from 'vue-drag-resize' //缩放、拖拽

// 安装自定义组件入口
const vuePlugins = {
    install: function(Vue) {
        Vue.component('extend', VueExtend) // 扩展vue一些常用的方法组件
        Vue.component('extend-date', VueExtendDate) // 日期工具组件，home.vue中有引入
        Vue.component('my-icon', VueIcon) // icon图标组件
        Vue.component('vue-drag-resize', VueDragResize)
    }
}

// 导出组件
export default vuePlugins