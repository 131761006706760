import router from "./router"
import { getToken } from "@/utils/auth"
import { isMobile } from "@/utils/mobile"

const isLoginPageList = ['index'] // no redirect whitelist

router.beforeEach((to, from, next) => {
  if (to.name === 'mobile') {
    console.log('mobile界面，直接放行');
    next()
    return
  }

  if (isMobile()) {
    console.log('跳转到m页');
    next({
      name: 'mobile'
    })
    return
  }

  if (isLoginPageList.indexOf(to.name) > -1) { // 需要登录的界面
    if (!getToken()) { // 未登录
      next({
        name: 'login'
      })
    } else { // 是登录状态，放行
      next()
    }
  } else { // 不需要登录的界面
    next()
  }
})
