/* eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars */
'use strict'

var $protobuf = require('protobufjs/light')

var $root = ($protobuf.roots['default'] || ($protobuf.roots['default'] = new $protobuf.Root()))
  .setOptions({
    java_multiple_files: false,
    java_package: 'com.yibai.im.core.protobuf.message',
    java_outer_classname: 'MsgProto'
  })
  .addJSON({
    message: {
      nested: {
        MsgService: {
          methods: {
            broadcast: {
              requestType: 'RawPackage',
              responseType: 'BroadcastResp'
            },
            kick: {
              requestType: 'Raw',
              responseType: 'BroadcastResp'
            }
          }
        },
        Content: {
          fields: {
            type: {
              type: 'int32',
              id: 1
            },
            payload: {
              type: 'bytes',
              id: 2
            },
            ext: {
              keyType: 'string',
              type: 'string',
              id: 3
            }
          }
        },
        From: {
          fields: {
            uid: {
              type: 'string',
              id: 1
            },
            nickname: {
              type: 'string',
              id: 2
            },
            avatar: {
              type: 'string',
              id: 3
            },
            admin: {
              type: 'bool',
              id: 4
            },
            seq: {
              type: 'int32',
              id: 5
            },
            timestamp: {
              type: 'int64',
              id: 6
            }
          }
        },
        To: {
          fields: {
            type: {
              type: 'int32',
              id: 1
            },
            id: {
              type: 'string',
              id: 2
            },
            specified: {
              type: 'string',
              id: 3
            }
          }
        },
        MetaData: {
          fields: {
            id: {
              type: 'string',
              id: 1
            },
            timestamp: {
              type: 'int64',
              id: 2
            },
            from: {
              type: 'From',
              id: 3
            },
            to: {
              type: 'To',
              id: 4
            }
          }
        },
        Raw: {
          fields: {
            content: {
              type: 'Content',
              id: 2
            },
            metaData: {
              type: 'MetaData',
              id: 3
            }
          }
        },
        RawPackage: {
          fields: {
            room: {
              type: 'string',
              id: 1
            },
            raws: {
              rule: 'repeated',
              type: 'Raw',
              id: 2
            }
          }
        },
        BroadcastResp: {
          fields: {
            result: {
              type: 'bool',
              id: 1
            }
          }
        }
      }
    }
  })

module.exports = $root
