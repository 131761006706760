
const state = {
    micDeviceList: [], //麦克风列表
    currentMicDevice: { deviceId: '' }, //当前麦克风设备
    audioVolumeValue: 30, //麦克风音量

    speakerDeviceList: [], //扬声器列表
    currentSpeakerDevice: { deviceId: ''}, //当前扬声器设备
    speakerVolumeValue: 30, //扬声器音量

    cameraDeviceList: [], //摄像头列表
    currentCameraDevice: { deviceId: ''}, //当前摄像头设备
    videoSource: [], //本地摄像头预览 source
    videoElement: null, //本地预览摄像头标签

    stats: {} //网络、音视频等技术指标
}

const mutations = {
    setMicDeviceList: (state, data) => {
        state.micDeviceList = data
    },
    setCurrentMicDevice: (state, data) => {
        state.currentMicDevice = data
    },
    setAudioVolumeValue: (state, data) => {
        state.audioVolumeValue = data
    }, 


    setSpeakerDeviceList: (state, data) => {
        state.speakerDeviceList = data
    },
    setCurrentSpeakerDevice: (state, data) => {
        state.currentSpeakerDevice = data
    },
    setSpeakerVolumeValue: (state, data) => {
        state.speakerVolumeValue = data
    },


    setCameraDeviceList: (state, data) => {
        state.cameraDeviceList = data
    },
    setCurrentCameraDevice: (state, data) => {
        state.currentCameraDevice = data
    },
    setVideoSource: (state, data) => {
        if(Array.isArray(data) && data.length > 0) {
           state.videoSource.push(...data) 
        } else {
           state.videoSource = [] 
        }
    },
    setVideoElement: (state, data) => {
        state.videoElement = data
    },
    updateStats: (state, data) => {
        for (const key in data) {
            state.stats[key] = data[key]
        }
    }

}
// 计算属性
const getters = {
    getMicDeviceList(state){
       return state.micDeviceList
    }
}

const actions = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
