var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Base from "../BasePlugin";
import { fetchUserInfo, updateUserInfo, modifyPersonalPassword, modifyEnterprisePassword, updatePersonConference } from '../../api/meet';
import { getToken, removeUserInfo } from '../../utils/auth';
import { SDK_ERROR } from '../../constant';
import { ResultError } from '../../utils/entity';
import { checkEmail, checkSmallPwd } from '../../utils/check';
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import CryptoJS from "crypto-js";
/**
 * 服务模块（用户认证、会议管理）
 */
var MeetSetting = /** @class */ (function (_super) {
    __extends(MeetSetting, _super);
    function MeetSetting(app, config) {
        return _super.call(this, app) || this;
    }
    /**
     * 2.退出登录
     * @returns {Promise}
    */
    MeetSetting.prototype.logout = function () {
        removeUserInfo();
        return Promise.resolve();
    };
    /**
     * 5.获取用户信息
     * @returns {Promise}
    */
    MeetSetting.prototype.getUserInfo = function () {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var error, result, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!getToken()) {
                            error = new ResultError('NOT_LOGIN', SDK_ERROR.NOT_LOGIN, 'getUserInfo: no login');
                            reject(error);
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, fetchUserInfo()];
                    case 2:
                        result = _a.sent();
                        if (result)
                            resolve(result);
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        reject(error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    };
    /**
     * 6.修改用户名
     * @param {*} userName 用户名
     * @returns {Promise}
    */
    MeetSetting.prototype.changeUserName = function (userName) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var error, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!getToken()) {
                            error = new ResultError('NOT_LOGIN', SDK_ERROR.NOT_LOGIN, 'changeUserName: no login');
                            reject(error);
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, updateUserInfo({ nickname: userName })];
                    case 2:
                        _a.sent();
                        resolve();
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        reject(error_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    };
    /**
     * 7.修改用户密码
     * @param {*} userName 用户名
     * @param {*} oldPassword 旧密码
     * @param {*} newPassword 新密码
     * @returns {Promise}
     */
    MeetSetting.prototype.changeUserPassword = function (userName, oldPassword, newPassword, enterpriseId) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var error, error, error, error, params, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!userName) {
                            error = new ResultError('PARAMETERS', SDK_ERROR.PARAMETERS, 'changeUserPassword: parameter error');
                            reject(error);
                            return [2 /*return*/];
                        }
                        if (oldPassword === newPassword) {
                            console.log("原密码和新密码一致");
                            error = new ResultError('OLDPASSWORD_EQUAL_NEWPASSWORD', SDK_ERROR.OLDPASSWORD_EQUAL_NEWPASSWORD, 'changeUserPassword: old password is different from the new password');
                            reject(error);
                            return [2 /*return*/];
                        }
                        if (!oldPassword || !newPassword) {
                            console.log("原密码或新密码为空");
                            error = new ResultError('OLDPASSWORD_OR_NEWPASSWORD_EMAPTY', SDK_ERROR.OLDPASSWORD_OR_NEWPASSWORD_EMAPTY, 'changeUserPassword: old password or new password is empty');
                            reject(error);
                            return [2 /*return*/];
                        }
                        if (enterpriseId && enterpriseId !== '0' && !checkEmail(userName)) {
                            error = new ResultError('EMAIL_FORMAT', SDK_ERROR.EMAIL_FORMAT, "changeUserPassword: email(" + userName + ") format error");
                            reject(error);
                            return [2 /*return*/];
                        }
                        params = {};
                        params.password = Base64.stringify(CryptoJS.enc.Utf8.parse(sha256(oldPassword).toString()));
                        params.newPassword = Base64.stringify(CryptoJS.enc.Utf8.parse(sha256(newPassword).toString()));
                        params.username = userName;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        if (!(enterpriseId && enterpriseId !== '0')) return [3 /*break*/, 3];
                        params.enterpriseId = enterpriseId;
                        return [4 /*yield*/, modifyEnterprisePassword(params)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, modifyPersonalPassword(params)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        resolve('');
                        return [3 /*break*/, 7];
                    case 6:
                        error_3 = _a.sent();
                        reject(error_3);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        }); });
    };
    /**
     * 8.修改个人会议号信息
     * @param {*} entity 个人会议号信息实体
     * @returns {Promise}
     */
    MeetSetting.prototype.changePersonalMeetingInfo = function (conferenceNo, entity) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var error, error, result, error_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!entity || !conferenceNo) {
                            error = new ResultError('PARAMETERS', SDK_ERROR.PARAMETERS, 'changePersonalMeetingInfo: parameter error');
                            reject(error);
                            return [2 /*return*/];
                        }
                        if ((entity.passwordEnable && !checkSmallPwd(entity.password))
                            || (entity.ownerPasswordEnable && !checkSmallPwd(entity.ownerPassword))) {
                            error = new ResultError('PASSWORD_FORMAT', SDK_ERROR.PASSWORD_FORMAT, 'changePersonalMeetingInfo: password format error');
                            reject(error);
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, updatePersonConference(conferenceNo, entity)];
                    case 2:
                        result = _a.sent();
                        if (result)
                            resolve(result);
                        return [3 /*break*/, 4];
                    case 3:
                        error_4 = _a.sent();
                        reject(error_4);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    };
    return MeetSetting;
}(Base));
export default MeetSetting;
