import { MAX_SHARE_VIDEO_FPS, DEFAULT_VOLUME } from '../constant/index'

// 设置项缓存值
const setInfoKey = "LOCAL_MEETTING_SET";

/**
 * audioDeviceId: ''
 * audioValue: 50,
 *
 * speakerDeviceId: ''
 * speakerValue: ''
 *
 * videoDeviceId: ''
 * videoQuality: '' //视频画质
 *
 * joinDuration: 1 //是否显示我的参会时长 1|0 默认1
 * shareLimit: 0 //是否设置我的屏幕共享限制 0|1 默认0
 * shareLimitValue: '' //共享限制每秒帧数
 */
export function initLocalSetInfo() {
   const setCacheInfo = getLocalSetInfo()
   if (Object.keys(setCacheInfo).length > 0) {
     return
   }
   console.log(85855555)

   setLocalSetInfo(
     {
       audioDeviceId: '',
       audioValue: DEFAULT_VOLUME,
       speakerDeviceId: '',
       speakerValue: DEFAULT_VOLUME,
       videoDeviceId: '',
       videoQuality: 0,
       joinDuration: 1,
       shareLimit: 0,
       shareLimitValue: MAX_SHARE_VIDEO_FPS,
       language: 'auto'
     }
   )
}

export function getLocalSetInfo() {
  const data = localStorage.getItem(setInfoKey)

  return data ? JSON.parse(data) : {}
}

export function setLocalSetInfo(data) {
const localData = getLocalSetInfo()

localStorage.setItem(
    setInfoKey,
    JSON.stringify({
      ...localData,
      ...data,
    })
  );
}

export function removeLocalSetInfo() {
  localStorage.removeItem(setInfoKey)
}
