/*
 * 时间倒计时，整计时功能
 * version: 1.0
 * author:  SunAo
 * time: 2021-03-01
 * compatible: IE10+、firefox、Chrome
 */

export default {
    install(Vue, options) {
        Vue.prototype.$timer = function(second, type) {
            if (!second) {
                second = 1 // 默认为1 从00:00:00开始计时
            }

            if (type === undefined) {
                type = true // 默认计时
            }

            let timer = new Timer(second, type)
            return timer
        }
    }
}

/**
 * 计时器类
 * @param {number} time 初始时间(1从00:00开始)
 * @param {boolean} type 默认为true正计时，false为倒计时
 * @author 孙奥
 */
function Timer(time, type) {
    this.updateCallBack = null
    this.stopCallBack = null
    this.init(time, type)
}

/**
 * 初始化
 */
Timer.prototype.init = function(time, type) {
    this.time = time || parseInt(Math.random() * 3600)
    this.timerId = null

    if (type === undefined) {
        this.type = true
    } else {
        this.type = type
    }
}

/**
 * 开始/恢复
 */
Timer.prototype.start = function() {
    !this.timerId && this.reTime()
}

/**
 * 暂停/停止
 */
Timer.prototype.stop = function() {
    if (this.timerId) {
        clearTimeout(this.timerId)
        this.timerId = null
    }
}

/**
 * 刷新时间
 * @return {string} 时间
 */
Timer.prototype.reTime = function() {
    if (this.type) {
        this.time++

    } else if (this.time > 0) {

        this.time--
    } else {
        // 等于零结束计时
        this.stop()
        if (this.stopCallBack) {
            this.stopCallBack()
        }
        return false
    }

    // 输出时间
    if (this.updateCallBack) {
        this.updateCallBack(this.formatOutput())
    }
    // 递归
    this.timerId = setTimeout(this.reTime.bind(this), 1000)
}

/**
 * 输出时间
 */
Timer.prototype.formatOutput = function() {
    // 获取时分秒
    let h = parseInt(this.time / 3600)
    let m = parseInt((this.time - h * 3600) / 60)
    let s = this.time - h * 3600 - m * 60

    // 格式化
    h < 10 ? h = `0${h}` : ''
    m < 10 ? m = `0${m}` : ''
    s < 10 ? s = `0${s}` : ''

    // console.log(`${h}:${m}:${s}`)
    let result = `${m}:${s}`
    if (h !== '00') {
        result = `${h}:${m}:${s}`
    }
    return result
}

// 设置时间更新的回调函数
Timer.prototype.setUpdate = function(callback) {
    this.updateCallBack = callback
    return this
}

// 设置时间停止的回调函数
Timer.prototype.setStop = function(callback) {
    this.stopCallBack = callback
    return this
}