// 定义项目配置文件
export default {
    install(Vue, options) {
        Vue.prototype.$configs = {
            userId: '', // 用户Id
            roomId: '', // 房间Id
            peerId: '', // 会议服务器用户Id
            userName: '', // 用户名
            avatarUrl: '', // 头像
            conferenceNo: '', // 会议ID

            attendMap: {}, // 权限用户集合
            timeMs: new Date() - 24 * 60 * 60 * 1000,

            businessType: '10006',//业务类型 10006 医百会议，10007 sass
            hospital: '',//医院名称
        }
    }
}