const getters = {
  token: state => state.user.token,
  userId: state => state.user.userId,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  conferenceNo: state => state.user.conferenceNo,
  realName: state => state.user.realName,
  isShowSettingPanel: state => state.isShowSettingPanel,
  settingPanelDefaultIndex: state => state.settingPanelDefaultIndex,
  isShowDeviceSmallWin: state => state.isShowDeviceSmallWin
}
export default getters
