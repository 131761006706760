
import { DEFAULT_VOLUME, DEVICE_AUTH_SUCCESS, DEVICE_AUTH_FAIL } from "@/constant/index";

import { loganLog } from "@/utils/log"
import { getBrowser } from "@/utils/tool"

import {
   getDeviceAuth,
   setDeviceAuth,
   checkDevicePermission
} from "@/utils/device"
import debounce from 'lodash.debounce'
import { beautLicense } from '@/config/index';
import { error } from "jquery";
import { getLocalDeviceInfo } from "@/utils/device";
import { strToBoolean } from "@/utils/str";

function DeviceManager(vueInstance) {
   this.vue = vueInstance
   this.audioContext = null
   this.soundMeter = null
   this.micTestVolume  = 0
   this.micTestVolumeQueryInterval = null
   this.micStream = null
   this.stopCameraInterval = null
   this.beaut_gpuLevel = 'low'
   this.init()
}

// import { BeautyAppType } from "@/meet-sdk/module/rtc/lib/RoomParams";

//初始化
DeviceManager.prototype.init = async function(type=false) {
   this.deviceRoomClient = this.vue.$i100MeetSDK.settings
   // if(!type){
   const browser = getBrowser()
   // if (browser.chrome && browser.isMac || (browser.safari && browser.safari >= 15)){

   // if (!(browser.safari && browser.safari < 15)){ //美颜到期暂不续费
   //    try {
   //       this.beautyManage = await this.vue.$i100MeetSDK.i100MeetingControl.getBeautyManager({
   //          appId:beautLicense.appId,
   //          licenseKey:beautLicense.licenseKey,
   //          token:beautLicense.token,
   //          beautify: true,
   //          segmentation: true,
   //       })
   //       console.error('beautyManage:getBeautyManager success',this.beautyManage._gpuLevel)
   //       this.beaut_gpuLevel = this.beautyManage._gpuLevel
   //       this.vue.$store.commit('_gpuLevel', this.beautyManage._gpuLevel)
   //       // this.beaut_gpuLevel = 'low'
   //       // this.vue.$store.commit('_gpuLevel', 'low')
   //       this.setBeautFn()

   //    } catch (error) {
   //       loganLog(`beautyManage:getBeautyManager error=${JSON.stringify(error)}`)
   //    }
   // }else{
   //    loganLog(`beautyManage:浏览器不支持美颜--safari:${browser.safari}-chrome:${browser.chrome}-isMac:${browser.isMac}`)
   // }


   this.undataDeviceList(true)

   //设备热插拔
   this.devicechangeHandle()

   //初始化调用获取分辨率
   this.getDeviceResolution()
}


DeviceManager.prototype.setBeautFn = async function() {
   //设置美颜相关
   const isBeauty = strToBoolean(this.vue.$loadLocal("isBeauty"), false); //是否开启美颜
   if(isBeauty && this.beaut_gpuLevel == 'high'){
     const localDeviceData = getLocalDeviceInfo();
     const videoBeautLeave = localDeviceData.videoBeautLeave || 1; //美颜等级

     if(videoBeautLeave == 4){
       this.setBeautyLevel(0)
       const whitenessLevel = localDeviceData.whitenessLevel || 0; //美白等级
       const dermabrasionLeve = localDeviceData.dermabrasionLeve || 0; //磨皮等级
       const faceSlimLeve = localDeviceData.faceSlimLeve || 0; //瘦脸等级
       const faceShaveLevel = localDeviceData.faceShaveLevel || 0; //削脸等级
       const eyeScaleLevel = localDeviceData.eyeScaleLevel || 0; //大眼等级
       const chinLevel = localDeviceData.chinLevel || 0; //下巴等级
       whitenessLevel && this.setBeauty('setWhitenessLevel',whitenessLevel)
       dermabrasionLeve && this.setBeauty('setDermabrasionLevel',dermabrasionLeve)
       faceSlimLeve && this.setBeauty('setFaceSlimLevel',faceSlimLeve)
       faceShaveLevel && this.setBeauty('setFaceShaveLevel',faceShaveLevel)
       eyeScaleLevel && this.setBeauty('setEyeScaleLevel',eyeScaleLevel)
       chinLevel && this.setBeauty('setChinLevel',chinLevel)
     }else{
       this.setBeautyLevel(videoBeautLeave)
     }
   }else{
     this.setBeautyLevel(0)
   }
}



// 更新设备列表
DeviceManager.prototype.undataDeviceList = async function(isInit = false) {
   //1.6改用底层SDK提供的方法，以前是在上层直接调用的navigator.mediaDevices
   //会前使用this.roomClient，后期底层SDK优化模块化实例
   //会中使用this.vue.$roomClien
   const micDeviceList =  await  this.deviceRoomClient.getMicDevicesList()
   micDeviceList.forEach(item => {
      item.deviceName = item.deviceName.replace(/立体声混音/g,"(不可用)立体声混音")
      if(item.deviceName.toLowerCase().match(/virtual/)){
         item.isDefault = false
         item.deviceName =  "(不可用)" + item.deviceName
      }
   })

   this.vue.$store.commit('device/setMicDeviceList', micDeviceList)
   if(micDeviceList && micDeviceList.length>0){
      loganLog(`麦克风设备列表 DeviceList:${JSON.stringify(micDeviceList)}`);
      this.setCurrentMicDevice(null, true,isInit) // 改成本人入会后或者断网重连后 强制set
      // this.initMicDeviceVolume(false) //入会后调用了
   }else{
      loganLog('无麦克风设备列表')
   }

   const speakerDeviceList = await  this.deviceRoomClient.getSpeakerDevicesList()

   this.vue.$store.commit('device/setSpeakerDeviceList', speakerDeviceList)
   if(speakerDeviceList && speakerDeviceList.length>0){
      loganLog(`扬声器设备列表 DeviceList:${JSON.stringify(speakerDeviceList)}`);
      this.setCurrentSpeakerDevice()
      this.initPlayoutVolume(null, false)
   }else{
      loganLog('无扬声器设备列表')
   }


   const camDeviceList = await  this.deviceRoomClient.getCameraDevicesList()

   this.vue.$store.commit('device/setCameraDeviceList', camDeviceList)
   if(camDeviceList && camDeviceList.length>0){
      loganLog(`摄像头设备列表 DeviceList:${JSON.stringify(camDeviceList)}`);
      //初始化摄像头 不用这里初始化 要放在入会后
   this.setCurrentCameraDevice(null, false, false)
   }else{
      loganLog('无摄像头设备列表')
   }
},
//设备热插拔处理
DeviceManager.prototype.devicechangeHandle = async function() {
   // --------------------------------设备热插拔处理 ------------------------
   const deviceChangeEvent = debounce(async function() {
      //异步先行
      let _ismeeting = window.location.pathname.match(/meeting/)
      let _isjoin = window.location.pathname.match(/join/)

      const localAudioDeviceId = await this.vue.$i100MeetSDK.i100Settings.getConfig('audioDeviceId')
      const localSpeakerDeviceId = await this.vue.$i100MeetSDK.i100Settings.getConfig('speakerDeviceId')

      const newSpeakerDeviceList = await this.deviceRoomClient.getSpeakerDevicesList()
      const newCamDeviceList = await this.deviceRoomClient.getCameraDevicesList()
      const newMicDeviceList = await this.deviceRoomClient.getMicDevicesList()

      newMicDeviceList.forEach(item => {
         item.deviceName = item.deviceName.replace(/立体声混音/g,"(不可用)立体声混音")
         if(item.deviceName.toLowerCase().match(/virtual/)){
            item.isDefault = false
            item.deviceName =  "(不可用)" + item.deviceName
         }
      })

      const {
         micDeviceList,
         speakerDeviceList,
         cameraDeviceList
      } = this.vue.$store.state.device
      //麦克风
      if (JSON.stringify(micDeviceList) !== JSON.stringify(newMicDeviceList)) {
         this.vue.$store.commit('device/setMicDeviceList', newMicDeviceList)
         this.vue.$store.commit("meet/updateGlobalMeetState", {
            "micNoGrant":null
         })

         console.error(micDeviceList)
         console.error(newMicDeviceList)
         let deviceId = '',deviceName

         if(newMicDeviceList.length === 1){
            deviceId = newMicDeviceList[0].deviceId
            deviceName = newMicDeviceList[0].deviceName
         }else if(micDeviceList.length && newMicDeviceList.length && newMicDeviceList.length > micDeviceList.length){
            const obj = {}
            //将新插入的设备切换为当前设备
            micDeviceList.forEach(item => obj[`${item.deviceId}`] = true)
            newMicDeviceList.forEach(item => {
               if(!obj[item.deviceId]){
                  deviceId = item.deviceId
                  deviceName = item.deviceName
               }
            })
         } else{
            newMicDeviceList.forEach(item => {
               if(item.isDefault){
                  deviceId = item.deviceId
                  deviceName = item.deviceName
               }
            })
         }

         loganLog(`麦克风 deviceChange 执行了---deviceId:${deviceId}---localAudioDeviceId:${localAudioDeviceId}`)

         if(deviceId){
            if(localAudioDeviceId === deviceId){
               loganLog("当前麦克风设备ID没变")
               await this.setCurrentMicDevice()
            }else{
               loganLog(`麦克风变更为${deviceName}`)
               _ismeeting && _ismeeting.index && this.vue.$totast(`您的麦克风已更换为${deviceName}`)
               await this.setCurrentMicDevice(deviceId,true)
            }
         }else{
            loganLog("非deviceId")
            await this.setCurrentMicDevice()
         }
      }

      //扬声器
      if (JSON.stringify(speakerDeviceList) !== JSON.stringify(newSpeakerDeviceList)) {
         this.vue.$store.commit('device/setSpeakerDeviceList', newSpeakerDeviceList)
         loganLog(`扬声器 deviceChange 执行了`)

         let deviceId = '',deviceName
         if(newSpeakerDeviceList.length === 1){
            deviceId = newSpeakerDeviceList[0].deviceId
            deviceName = newSpeakerDeviceList[0].deviceName
         }else if(speakerDeviceList.length && newSpeakerDeviceList.length && newSpeakerDeviceList.length > speakerDeviceList.length){
            const obj = {}
            //将新插入的设备切换为当前设备
            speakerDeviceList.forEach(item => obj[`${item.deviceId}`] = true)
            newSpeakerDeviceList.forEach(item => {
               let _name = item.deviceName.toLowerCase()
               // console.error(99999)
               // console.error('tv',/tv/.test(_name))
               // console.error('airplay',/airplay/.test(_name))
               // console.error('display',/display/.test(_name))
               // console.error('hdmi',/hdmi/.test(_name))
               // console.error('digital output',/digital output/.test(_name))
               // console.error('monitor',/monitor/.test(_name))
               // console.error('显示器',/显示器/.test(_name))

               if(!obj[item.deviceId] && !/tv/.test(_name) && !/airplay/.test(_name) && !/display/.test(_name) && !/hdmi/.test(_name) && !/digital output/.test(_name) && !/monitor/.test(_name) && !/显示器/.test(_name) && !/LG Ultra HD/.test(_name)){
                  deviceId = item.deviceId
                  deviceName = item.deviceName
               }
            })
         } else{
            newSpeakerDeviceList.forEach(item => {
               if(item.isDefault){
                  deviceId = item.deviceId,
                  deviceName = item.deviceName
               }
            })
         }

         if(deviceId){
            if(localSpeakerDeviceId === deviceId){
               console.error("当前扬声器设备ID没变")
               this.setCurrentSpeakerDevice()
            }else{
               loganLog(`扬声器变更为${deviceName}`)
               _ismeeting && _ismeeting.index && this.vue.$totast(`您的扬声器已更换为${deviceName}`)
               this.setCurrentSpeakerDevice(deviceId)
            }
         }else{
            this.setCurrentSpeakerDevice()
         }
      }

      // console.error(12121)
      // console.error(cameraDeviceList)
      // console.error(newCamDeviceList)
      //摄像头
      if (JSON.stringify(cameraDeviceList) !== JSON.stringify(newCamDeviceList)) {
         this.vue.$store.commit('device/setCameraDeviceList', newCamDeviceList)
         loganLog(`摄像头 deviceChange 执行了`)
         console.error(cameraDeviceList,newCamDeviceList)
         let localDeviceID = await this.vue.$i100MeetSDK.i100Settings.getConfig('videoDeviceId')
         let isHasLocalId = false
         newCamDeviceList.forEach(item => {
            if(item.deviceId === localDeviceID){
               isHasLocalId = true
            }
         })
         if(cameraDeviceList.length && newCamDeviceList.length && newCamDeviceList.length > cameraDeviceList.length && isHasLocalId){
            loganLog('摄像头发生变化，旧的设备还存在 不切换新设备')
            return
         }
         const deviceId = await this.setCurrentCameraDevice()

         this.vue.$store.commit("meet/updateGlobalMeetState", {
            "cameraNoGrant":null
         })

         if (deviceId) {
            console.error('渲染摄像头预览执行了...')
            if (this.vue.$store.state.isShowVideoPanel) {
               this.startLocalCamera(deviceId, deviceId +'_video')
            }
            if (this.vue.$store.state.isShowSettingPanel && [2,3,4].includes(this.vue.$store.state.settingPanelDefaultIndex)) {
               this.startLocalCamera(deviceId, 'settingVideo')
            }else if (this.vue.$store.state.isShowJoinVidewView && _isjoin) {
               this.startLocalCamera(deviceId, 'joinVideo')
            }
         }
      }
      // console.error(`deviceChangeEvent 执行了`)
   },900, { leading: false, trailing: true})
   if (navigator.mediaDevices.ondevicechange) {
      navigator.mediaDevices.ondevicechange = deviceChangeEvent.bind(this)
   }else {
      navigator.mediaDevices.ondevicechange = deviceChangeEvent.bind(this)

      // navigator.mediaDevices.addEventListener('devicechange', deviceChangeEvent.bind(this))
   }
}

// 获取设备分辨率
DeviceManager.prototype.getDeviceResolution = async function(deviceId) {
   if(!deviceId) return [];
   try {
      let deviceResolution = await this.deviceRoomClient.getConstrinsSupport(deviceId)
      loganLog(`getDeviceResolution-----deviceId${deviceId}------deviceResolution: ${JSON.stringify(deviceResolution)}`)

      return deviceResolution
   } catch (error) {
      loganLog(`getDeviceResolution失败-----deviceId${deviceId}------error: ${JSON.stringify(error)}`)
   }
}

//音视频设备权限初始化
DeviceManager.prototype.initDevicePermission = function() {
      const deviceAuthValue = getDeviceAuth()
      // if (DEVICE_AUTH_SUCCESS === deviceAuthValue) { // 先注释掉 每次初始化都尝试check一下权限
      //    // this.init('initDevicePermission')
      //    return
      // }
      checkDevicePermission()
      .then(() => {
      //   this.init('initDevicePermission')
        setDeviceAuth(DEVICE_AUTH_SUCCESS)
      })
      .catch(() => {
        setDeviceAuth(DEVICE_AUTH_FAIL)
     })
}

// ------------------------------ 设置美颜级别 ---------------------------------------
DeviceManager.prototype.setBeautyLevel = async function(leave) {
   try{
      this.beautyManage &&  this.beautyManage.setBeautyLevel(leave)
      loganLog(`setBeautyLevel--value:${leave}`)
   } catch(error){
      loganLog(`setBeautyLevel error${JSON.stringify(error)}`)
   }
}


// ------------------------------ 设置Ar人像分割（虚拟背景 ---------------------------------------
DeviceManager.prototype.setVirtuallyBackground = async function(options) {
   console.log('[plugins Device.ts]setVirtuallyBackground()|准备设置Ar人像分割params: ', options);

   if(options.type === 'transparent') {
     this.clearVirtuallyBackground();
     return;
   }
   this.beautyManage && this.beautyManage.setVirtuallyBackground(options.type, options.src);
}
// ------------------------------ 清除Ar人像分割（虚拟背景）---------------------------------------

DeviceManager.prototype.clearVirtuallyBackground = async function() {
   this.beautyManage && this.beautyManage.clearVirtuallyBackground();
}


// ------------------------------ 关闭预览 ---------------------------------------

DeviceManager.prototype.stopLocalPreviewOnly = async function(videoElement,msg='') {
   console.error('stopLocalPreviewOnly')
   if (videoElement) {
      videoElement.src = ""
      videoElement.srcObject = null
   }
   try {
      await this.vue.$i100MeetSDK.i100MeetingControl.stopLocalPreviewOnly(msg);
      loganLog(`beautyManage:stopLocalPreviewOnly success --msg:${msg}`)
   } catch (error) {
      loganLog(`beautyManage:stopLocalPreviewOnly --msg:${msg} ----error:${JSON.stringify(error)}`)
   }
}
// ------------------------------ 开启预览 ---------------------------------------
// 加防抖是因为点击设置的时候watch和
DeviceManager.prototype.startLocalPreviewOnly = debounce(async function(deviceId,element,cb) {
   // console.error('startLocalPreviewOnly')
   loganLog(`beautyManage:startLocalPreviewOnly start--element:${element.id}`)
   try {
      //先执行一遍stop
      // await this.stopLocalPreviewOnly(null,'preStart')
      let width = 640,height =360
      let videoResolution = await this.vue.$i100MeetSDK.i100Settings.getConfig('videoResolution')
      let quality = await this.vue.$i100MeetSDK.i100Settings.getConfig('videoQuality')

      // 1280x720","1920x1080
      if(quality === 1 && videoResolution == 720){
         width =1280
         height = 720
      }
      if(quality === 1 && videoResolution == 1080){
         width =1920
         height = 1080
      }
      console.error('采集分辨率',width,height)

      console.error('beautView set',width,height,deviceId,element)
      loganLog(`beautView set ${width},${height}`)
      await this.vue.$i100MeetSDK.i100MeetingControl.startLocalPreviewOnly(width,height,deviceId,element);
      cb && cb('success')
      loganLog('beautyManage:startLocalPreviewOnly success')
   } catch (error) {
      cb && cb('error')
     loganLog(`beautyManage:startLocalPreviewOnly----error:${JSON.stringify(error)}`)
   }
}, 300, { leading: false, trailing: true}),


// ------------------------------ 设置美颜内容 ---------------------------------------
DeviceManager.prototype.setBeauty = async function(name,value) {
    try {
      loganLog(`setBeauty--${name}--value:${value}`)
      this.beautyManage && await this.beautyManage[name](value)
    } catch (error) {
      loganLog(`setBeauty----${name} --value:${value}- error${JSON.stringify(error)}`)
    }
}



// ------------------------------ 麦克风设备 ---------------------------------------

//设置要使用的麦克风
DeviceManager.prototype.setCurrentMicDevice = async function(deviceId, isSet = true, isInit = false) {
   let micDeviceList = this.vue.$store.state.device.micDeviceList
   loganLog(`setCurrentMicDevice 准备执行`);

   const cacheDeviceId = await this.vue.$i100MeetSDK.i100Settings.getConfig('audioDeviceId')
   let queryDeviceId = deviceId || cacheDeviceId

   if(!micDeviceList || micDeviceList.length == 0 || micDeviceList.length == 1 && !micDeviceList[0].deviceId){
      loganLog(`setCurrentMicDevice micDeviceList为空 执行undataDeviceList `);

      checkDevicePermission()
      .then(() => {
         this.undataDeviceList()
      })
      .catch(() => {
        setDeviceAuth(DEVICE_AUTH_FAIL)
     })

      micDeviceList =  await  this.deviceRoomClient.getMicDevicesList()
      micDeviceList.forEach(item => {
         item.deviceName = item.deviceName.replace(/立体声混音/g,"(不可用)立体声混音")
         if(item.deviceName.toLowerCase().match(/virtual/)){
            item.isDefault = false
            item.deviceName =  "(不可用)" + item.deviceName
         }
      })
   }

   let currentDevice = micDeviceList.find(item => item.deviceId === queryDeviceId)

   if (!currentDevice && micDeviceList.length > 0) {
      currentDevice = micDeviceList[0]
      queryDeviceId = micDeviceList[0].deviceId
   }

   if(!currentDevice) loganLog(`setCurrentMicDevic 错误 没有currentDevice`);

   if(!queryDeviceId) {
      loganLog(`setCurrentMicDevic错误 没有queryDeviceId return了`);
      return
   }

   this.vue.$store.commit('device/setCurrentMicDevice', currentDevice)
   if (cacheDeviceId && queryDeviceId === cacheDeviceId && !isInit) {
      loganLog(`setCurrentMicDevice 由于重复设置return 了`);
      return
   }



   //---------------- SDK 设置麦克风测试 -------------------------
   if(isSet) {
      await this.vue.$i100MeetSDK.i100Settings.setConfig('audioDeviceId', queryDeviceId)
      loganLog(`setCurrentMicDevice 执行了 ---queryDeviceId:${queryDeviceId}`);
      await this.vue.$i100MeetSDK.i100Settings.setCurrentMicDevice(queryDeviceId)
      loganLog(`当前麦克风 ${queryDeviceId}`)
   }
}

//初始化麦克风音量
//修改:初始化不走缓存，默认100%
DeviceManager.prototype.initMicDeviceVolume = async function(isSet = true) {

   const volume = await this.vue.$i100MeetSDK.i100Settings.getConfig('audioValue')

   this.vue.$store.commit('device/setAudioVolumeValue', volume)

   //---------------- SDK 设置麦克风音量测试 -------------------------
   if (isSet) {
      this.vue.$i100MeetSDK.i100Settings.setCurrentMicDeviceVolume(Number(volume))
      console.error(`当前麦克风音量 ${volume}`)
   }
}

//获取当前麦克风设备的音量
// DeviceManager.prototype.getCurrentMicDeviceVolume = async function() {
//     //---------------- SDK 获取麦克风音量测试 -------------------------
//     return await this.vue.$i100MeetSDK.i100Settings.getCurrentMicDeviceVolume()
// }

//设置麦克风设备的音量
DeviceManager.prototype.setCurrentMicDeviceVolume = async function(number, isSet = true) {
   await this.vue.$i100MeetSDK.i100Settings.setConfig('audioValue', number)

   this.vue.$store.commit('device/setAudioVolumeValue', number)

   //---------------- SDK 设置麦克风音量测试 -------------------------
   if (isSet) {
      await this.vue.$i100MeetSDK.i100Settings.setCurrentMicDeviceVolume(Number(number))
   }
}

//设置要使用的扬声器
DeviceManager.prototype.setCurrentSpeakerDevice = async function(deviceId, audioViews, isInit = false) {
   const speakerDeviceList = this.vue.$store.state.device.speakerDeviceList

   const cacheDeviceId = await this.vue.$i100MeetSDK.i100Settings.getConfig('speakerDeviceId')
   let queryDeviceId = deviceId || cacheDeviceId
   let currentDevice = speakerDeviceList.find(item => item.deviceId === queryDeviceId)
   if (!currentDevice && speakerDeviceList.length > 0) {
      let _name = speakerDeviceList[0].deviceName.toLowerCase()
      if(!/tv/.test(_name) && !/airplay/.test(_name) && !/display/.test(_name) && !/hdmi/.test(_name) && !/digital output/.test(_name) && !/monitor/.test(_name) && !/显示器/.test(_name)){
         currentDevice = speakerDeviceList[0]
         queryDeviceId = speakerDeviceList[0].deviceId
      }else{
         if(speakerDeviceList.length > 1){
            currentDevice = speakerDeviceList[1]
            queryDeviceId = speakerDeviceList[1].deviceId
         }else{
            currentDevice = speakerDeviceList[0]
            queryDeviceId = speakerDeviceList[0].deviceId
         }
      }
   }

   this.vue.$store.commit('device/setCurrentSpeakerDevice', currentDevice)

   const elements = audioViews || document.querySelectorAll('.audioPlayoutVolumeItem')

   if (cacheDeviceId && queryDeviceId === cacheDeviceId && !isInit) {
      loganLog(`setCurrentSpeakerDevice 由于重复设置return了`);
      return
   }

   await this.vue.$i100MeetSDK.i100Settings.setConfig('speakerDeviceId', queryDeviceId)
   //const elements = audioViews || document.querySelectorAll('.audioPlayoutVolumeItem')

   //---------------- SDK 设置扬声器测试 -------------------------
   await this.vue.$i100MeetSDK.i100Settings.setCurrentSpeakerDevice(queryDeviceId, elements)
}

//获取当前扬声器设备的音量
// DeviceManager.prototype.getPlayoutVolume = async function() {
//    //---------------- SDK 获取扬声器音量测试 -------------------------
//    return await this.vue.$i100MeetSDK.i100Settings.getCurrentSpeakerDeviceVolume()
// }

//初始化 当前扬声器设备的音量
DeviceManager.prototype.initPlayoutVolume = async function(audioViews, isSet = true) {

   const volume = await this.vue.$i100MeetSDK.i100Settings.getConfig('speakerValue')

   this.vue.$store.commit('device/setSpeakerVolumeValue', volume)

   const elements = audioViews || document.querySelectorAll('.audioPlayoutVolumeItem')

   //---------------- SDK 设置扬声器音量测试 -------------------------
   if (elements && isSet) {
      if(this.vue.$store.state.meet.isShareDoc){ //如果是自己开启的云共享 不需要设置云共享音量
         audioEl = Array.prototype.filter.call(audioEl,(i)=>{return i.id != 'share_audio'})
      }
      loganLog(`set扬声器--volume:${volume}`)
      await this.vue.$i100MeetSDK.i100Settings.setCurrentSpeakerDeviceVolume(elements, Number(volume))
   }
}

//设置 当前扬声器设备的音量
DeviceManager.prototype.setPlayoutVolume = async function(audioViews, number, isUseYangShengQi = false) {

   this.vue.$store.commit('device/setSpeakerVolumeValue', number)

   await this.vue.$i100MeetSDK.i100Settings.setConfig('speakerValue',Number(number))


   if (isUseYangShengQi) {
      const elements = audioViews || document.querySelectorAll('.audioPlayoutVolumeItem')

      //---------------- SDK 设置扬声器音量测试 -------------------------
      await this.vue.$i100MeetSDK.i100Settings.setCurrentSpeakerDeviceVolume(elements, Number(number))
      console.error(`当前扬声器音量 ${number}`)
   }
}

// ------------------------------ 摄像头设备 ---------------------------------------
//摄像头列表
// DeviceManager.prototype.getCameraDevicesList = async function() {
//    //---------------- SDK 摄像头列表测试 -------------------------
//    const camDevices = await this.vue.$i100MeetSDK.i100Settings.getCameraDevicesList()
//    console.log(`getCameraDevicesList:${JSON.stringify(camDevices)}`)
//    return camDevices
// }

//获取当前摄像头设备
DeviceManager.prototype.getCurrentCameraDevice = async function() {

   //---------------- SDK 获取当前摄像头测试 ------------------------
   const camDeviceList = this.vue.$store.state.device.cameraDeviceList

   let queryDeviceId =  await this.vue.$i100MeetSDK.i100Settings.getConfig('videoDeviceId')
   if (!queryDeviceId && camDeviceList.length > 0) {
         queryDeviceId = camDeviceList[0].deviceId
   }
   return camDeviceList.find(item => item.deviceId === queryDeviceId)
}

//初始化( 摄像头设备
DeviceManager.prototype.initCurrentCameraDevice = async function() {
   // const deviceInfo = await this.getCurrentCameraDevice() || {}
   // if (deviceInfo.deviceId) {
      // loganLog(`初始化摄像头---${deviceInfo.deviceId}`)
      loganLog(`初始化摄像头准备执行`)
      try {
         // await this.vue.$i100MeetSDK.i100Settings.setCurrentCameraDevice(deviceInfo.deviceId)
         // 要走setCurrentCameraDevice的方法，不要直接调用底层sdk
         await this.setCurrentCameraDevice(null, true, true)

         loganLog(`初始化摄像头 initCurrentCameraDevice 执行了`);
      } catch (error) {
         loganLog(`摄像头设备 initCurrentCameraDevice失败了 error:${JSON.stringify(error)}`);
      }
   // }
}

//设置摄像头
DeviceManager.prototype.setCurrentCameraDevice = async function(deviceId, isSet = true, isInit = false) {
   const camDeviceList = this.vue.$store.state.device.cameraDeviceList

   let cacheDeviceId = await this.vue.$i100MeetSDK.i100Settings.getConfig('videoDeviceId')
   let queryDeviceId = deviceId || cacheDeviceId
   let currentDevice = camDeviceList.find(item => item.deviceId === queryDeviceId)
   if (!currentDevice && camDeviceList.length > 0) {
      currentDevice = camDeviceList[0]
      queryDeviceId = camDeviceList[0].deviceId
   }

   this.vue.$store.commit('device/setCurrentCameraDevice', currentDevice)
   loganLog(`摄像头设备 setCurrentCameraDevice 准备执行`);

   if (cacheDeviceId && queryDeviceId === cacheDeviceId && !isInit) {
      loganLog(`setCurrentCameraDevice 由于重复设置return了`);
      return queryDeviceId
   }

   await this.vue.$i100MeetSDK.i100Settings.setConfig('videoDeviceId', queryDeviceId)

   if (queryDeviceId && (isSet || isInit)) {

      //---------------- SDK 设置当前摄像头测试 -------------------------
      try {
         await this.vue.$i100MeetSDK.i100Settings.setCurrentCameraDevice(queryDeviceId)
         loganLog(`摄像头设备 setCurrentCameraDevice 执行了---queryDeviceId:${queryDeviceId}`);
      } catch (error) {
         loganLog(`摄像头设备 setCurrentCameraDevice失败了 error:${JSON.stringify(error)}`);
      }

      const peerId = this.vue.$configs.peerId
      const selfInfo = this.vue.$store.getters["member/getUser"](peerId)
      if (this.vue.$store.state.meet.isUseShiPin && selfInfo.userId) {

         //渲染video元素
         const videoEl = document.getElementById(`video-${peerId}`)
         if (videoEl) {
            await this.vue.$i100MeetSDK.i100MeetingControl.startLocalVideo(videoEl)
         }

         const videoMainEl = document.getElementById(`main-video-${peerId}`)
         if (videoMainEl) {
            await this.vue.$i100MeetSDK.i100MeetingControl.startLocalVideo(videoMainEl)
         }

         const videoTabEl = document.getElementById(`tab2-video-${peerId}`)
         if(videoTabEl) {
            await this.vue.$i100MeetSDK.i100MeetingControl.startLocalVideo(videoTabEl)
         }

         loganLog(`摄像头设备 当前摄像头 ${queryDeviceId}`);
      }

   }
   return queryDeviceId
}

//打开本地预览视频
DeviceManager.prototype.startLocalCamera = async function(deviceId, elementId) {
   let video = document.getElementById(elementId)
   if (!video) return
   try {
      await this.startLocalPreviewOnly(deviceId, video);
      loganLog(`打开本地预览 success`)
   } catch (error) {
      loganLog(`打开本地预览 error----${JSON.stringify(error)}`)
   }

   // if (this.stopCameraInterval) {
   //    clearInterval(this.stopCameraInterval)
   //    this.stopCameraInterval = null
   // }


   // try {
   //    const configObject = {
   //       width: width,
   //       height: height,
   //       deviceId: { ideal: deviceId }
   //    }
   //    const mediaStream = await navigator.mediaDevices.getUserMedia({ video : configObject})
   //    this.vue.$store.commit('device/setVideoSource', mediaStream.getVideoTracks())
   //    this.vue.$store.commit('device/setVideoElement', video)

   //    video.srcObject = mediaStream
   //    video.play()
   // } catch (error) {
   //    loganLog(`打开本地视频 start local camera error:${error}`);
   // }
}


//启动本地麦克风测试
DeviceManager.prototype.enableMicTest = async function() {
   await this.vue.$i100MeetSDK.i100Settings.enableMicTest()
   // try {
   //    //实例化AudioContext
   //    const audioContextCtor = window.AudioContext
	// 			|| window.webkitAudioContext
	// 			|| false

   //    this.audioContext = new audioContextCtor()
   // } catch (e) {
   //    console.error('Web Audio API is not supported')
   //    return
   // }

   // //SoundMeter声音测量,用于做声音音量测算使用的
   // this.soundMeter = new SoundMeter(this.audioContext);

   // try {
   //    this.micStream = await navigator.mediaDevices.getUserMedia({ audio: true });
   //    //将声音测量对象与流连接起来
   //    this.soundMeter.connectToSource(this.micStream);
   //    //开始实时读取音量值  每隔200毫秒调用一次soundMeterProcess函数,模拟实时检测音频音量
   //    this.micTestVolumeQueryInterval = setInterval(() => {
   //       //读取音量值,再乘以一个系数,可以得到音量条的宽度
   //       if (this.soundMeter) {
   //          this.micTestVolume = this.soundMeter.instant
   //          //console.log(`micTestVolume = ${this.micTestVolume}`)
   //       }
   //    }, 500)
   // } catch (error) {
   // }
}

//停止本地麦克风测试
DeviceManager.prototype.disableMicTest = function() {
   this.vue.$i100MeetSDK.i100Settings.disableMicTest()
   // //关闭定时器
   // if (this.micTestVolumeQueryInterval) {
   //    clearInterval(this.micTestVolumeQueryInterval)
   //    this.micTestVolumeQueryInterval = null
   // }
   // //断开sound meter
   // this.soundMeter && this.soundMeter.mic && this.soundMeter.stop()
   // //关闭stream
   // if (this.micStream) {
   //    var tracks, i, len;
   //    //判断是否有getTracks方法
   //    if (this.micStream.getTracks) {
   //       //获取所有的Track
   //       tracks = this.micStream.getTracks();
   //       //迭代所有Track
   //       for (i = 0, len = tracks.length; i < len; i += 1) {
   //          //停止每个Track
   //          tracks[i].stop();
   //       }
   //    } else {
   //       //获取所有的音频Track
   //       tracks = this.micStream.getAudioTracks();
   //       //迭代所有音频Track
   //       for (i = 0, len = tracks.length; i < len; i += 1) {
   //          //停止每个Track
   //          tracks[i].stop();
   //       }
   //    }
   // }
   // //变量重置
   // this.audioContext = null
   // this.soundMeter = null
   // this.micTestVolume = 0
   // this.micStream = null
}

//获取麦克风音量范围
DeviceManager.prototype.getMicTestVolume = async function() {
   //return this.micTestVolume
   return this.vue.$i100MeetSDK.i100Settings.getMicTestVolume()
}

// -------------------- 音视频 技术指标统计 -----------------
DeviceManager.prototype.computeLocalStats = async function() {
   if (this.vue.$i100MeetSDK.i100MeetingControl) {
      //从transport中获取本地音视频信息
      const localTransportStats = await this.vue.$i100MeetSDK.i100MeetingControl.getSendTransportLocalStats()
      //console.error('--------- 本地音视频信息 -----------')
      const that = this, stats = {}
      localTransportStats && localTransportStats.forEach(item => {
         //console.error(JSON.stringify(item))
         if (item.totalRoundTripTime > 0 && item.responsesReceived > 0) {
            stats.totalRoundTripTime = item.totalRoundTripTime
            stats.responsesReceived = item.responsesReceived
         }
         if (item.currentRoundTripTime > 0) {
            stats.currentRoundTripTime = item.currentRoundTripTime
         }
         if (item.totalRoundTripTime > 0 && item.roundTripTimeMeasurements > 0) {
            stats.totalRoundTripTime = item.totalRoundTripTime
            stats.roundTripTimeMeasurements = item.roundTripTimeMeasurements
         }

         if (item.hasOwnProperty('packetsLost')) {
            stats.packetsLost = item.packetsLost
         }
         if (item.hasOwnProperty('packetsReceived')) {
            stats.packetsReceived = item.packetsReceived
         }
         if (item.hasOwnProperty('packetsSent')) {
            stats.packetsSent = item.packetsSent
         }
      })
      //网络延迟
      let rtt = 0
      if(stats.currentRoundTripTime) {
         //console.error('延迟数据=>currentRoundTripTime')
         rtt = Math.floor(stats.currentRoundTripTime * 1000)
      }else if (stats.totalRoundTripTime && stats.responsesReceived) {
         //console.error('延迟数据=>totalRoundTripTime/responsesReceived')
         rtt = Math.floor(stats.totalRoundTripTime / stats.responsesReceived * 1000)
      }else if(stats.totalRoundTripTime && stats.roundTripTimeMeasurements){
         //console.error('延迟数据=>totalRoundTripTime/roundTripTimeMeasurements')
         rtt = Math.floor(stats.totalRoundTripTime / stats.roundTripTimeMeasurements * 1000)
      }
      that.vue.$store.commit('device/updateStats', { rtt : rtt })

   }
}


export default DeviceManager
