/**
 * 插件基类 - 用于拆分插件使用
 */
var Base = /** @class */ (function () {
    function Base(app) {
        this.app = app;
        // app 入口类的 执行上下文
        // 委托访问父类 - 原型方法
        this.initDelegate(this.app);
        // 入口类 -方法 到 子类的映射
    }
    Base.prototype.initDelegate = function (app) {
        var _this = this;
        var appProto = Object.getPrototypeOf(app);
        Object.keys(appProto).forEach(function (key) {
            _this[key] = app[key].bind(app);
            // hack method
        });
    };
    Base.prototype.destory = function () {
        throw 'destory must be implemented';
    };
    return Base;
}());
export default Base;
