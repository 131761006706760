/*
 * 医百会议工具类
 * version: 1.0
 * author:  SunAo
 * time: 2021-03-01
 * compatible: IE10+、firefox、Chrome
 */

export default {
    install(Vue, options) {
        Vue.prototype.$tools = new Tools()
    }
}

function Tools() {

}

// 格式化会议号
Tools.prototype.formatCode = function(confNo, fmtChar, maxLen) {
    fmtChar = fmtChar || " ";
    maxLen = maxLen || 11;
    if (confNo === '' || confNo === undefined) {
        return ''
    }
    confNo = confNo.toString().trim().replace(/[^\d]/g, "");

    if (confNo.length > maxLen) {
        confNo = confNo.substr(0, maxLen);
    }
    var result = confNo.substr(0, 3);
    if (confNo.length === 11) {
        result += fmtChar + confNo.substr(3, 4) + fmtChar + confNo.substr(7);
    } else {
        if (confNo.length >= 3) {
            result += fmtChar + confNo.substr(3, 3);
        }
        if (confNo.length >= 6) {
            result += fmtChar + confNo.substr(6);
        }
    }
    return result;
}

/**
 * 计算字符串的长度
 * @returns {number}
 */
Tools.prototype.gblen = function(str) {
    let len = 0;
    for (let i=0; i<str.length; i++) {
        if (str.charCodeAt(i)>127 || str.charCodeAt(i)==94) {
            len += 2;
        } else {
            len ++;
        }
    }
    return len;
}