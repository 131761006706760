<template>
  <div class="user-totast" :class="totast2?'user-totast-2':''" v-if="visible">
    {{ message }}
  </div>
</template>
 
<script>
  export default {
    data () {
      return {
        visible: false,
        type: 'info',
        message: '',
        duration: 3000,
        totast2:false
      }
    },
    methods: {
      setTimer() {
        setTimeout(() => {
          this.totast2 = false
          this.close() // 3000ms之后调用关闭方法
        }, this.duration)
      },
      close() {
        this.visible = false
        setTimeout(() => {
          this.$destroy(true)
          this.$el.parentNode.removeChild(this.$el) // 从DOM里将这个组件移除
        }, 500)
      }
    },
    mounted() {
      this.setTimer() // 挂载的时候就开始计时，3000ms后消失
      if(document.querySelectorAll('.user-totast').length > 0){
        this.totast2 = true
      }
    }
  }
</script>

<style>
.user-totast {
  z-index: 99999999;
  position: fixed;
  padding: 20px 40px;
  background: #333333;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  font-size: 16px;

  color: #fff;
}
.user-totast-2 {
  top: 58%;
}

</style>