import Vue from 'vue'
import Router from 'vue-router'

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

const routerReplace = Router.prototype.replace
Router.prototype.replace = function replace(location) {
  return routerReplace.call(this, location).catch(error => error)
}

Vue.use(Router)

const routes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/meetingManager/MeetingManager.vue')
  },
  {
    path: '/meeting/:userID',
    name: 'meeting',
    component: () => import('@/views/meeting/Meeting.vue')
  },
  {
    path: '/land',
    name: 'land',
    component: () => import('@/views/land/index.vue')
  },
  
  {
    path: '/forgotPwd',
    name: 'forgotPwd',
    component: () => import('@/views/login/Login.ForgotPwd.vue')
  },
  {
    path: '/finish',
    name: 'finish',
    component: () => import('@/views/login/Login.Finish.vue')
  },
  {
    path: '/finishRegister',
    name: 'finishRegister',
    component: () => import('@/views/login/Login.FinishRegister.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login.vue')
  },  
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register/Register.vue')
  }, 
  {
    path: '/join',
    name: 'join',
    component: () => import('@/views/join/Join.vue')
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: () => import('@/views/tips/mobile.vue')
  },
  {
    path: '/upgrade', 
    name: 'upgrade',
    component: () => import('@/views/tips/upgrade.vue')
  },
  {
    path: '*', // 页面不存在的情况下会跳到登录页
    redirect: '/login'
  }
]

const router = new Router({
  mode: 'history', // hash
  routes
})

export default router